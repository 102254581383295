import React from 'react';

import { IconContainer } from './styles';

interface IIconProps {
  color?: string;
  hoverColor?: string;
  icon: string;
}

const Icon: React.FC<IIconProps> = ({ color, hoverColor, icon }) => (
  <IconContainer color={color} icon={icon} hoverColor={hoverColor} />
);
export default Icon;
