/* eslint-disable no-case-declarations */
const inputsMasks = () => {
  const formatInputValue = (value: string): string => {
    const numericValue = parseFloat(value.replace(/\D/g, ''));
    if (Number.isNaN(numericValue)) {
      return '';
    }

    const valueCurrent = numericValue / 100;
    return valueCurrent.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'BRL',
    });
  };

  const formatInputNotCurrency = (value: string): string => {
    const numericValue = parseFloat(value.replace(/\D/g, ''));
    if (Number.isNaN(numericValue)) {
      return '';
    }

    const valueCurrent = numericValue / 100;
    return valueCurrent.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currency: 'BRL',
    });
  };

  const tensPlace = (value: string): string => {
    return Number(value).toLocaleString('pt-BR');
  };

  const formatHeightWidthMask = (dimensions: string): string => {
    const numericValue = parseFloat(dimensions.replace(/\D/g, ''));
    if (Number.isNaN(numericValue)) {
      return '';
    }

    const valueCurrent = numericValue / 100;
    const formatNumeric = valueCurrent.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const hasNonZeroValue = Math.floor(valueCurrent) !== 0;

    return `${hasNonZeroValue ? 'm' : 'cm'} ${formatNumeric}`;
  };

  const formatWeigth = (weigth: string): string => {
    const numericValue = parseFloat(weigth.replace(/\D/g, ''));
    if (Number.isNaN(numericValue)) {
      return '';
    }

    const valueCurrent = numericValue / 100;
    const formatNumeric = valueCurrent.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const hasNonZeroValue = Math.floor(valueCurrent) !== 0;

    return `${hasNonZeroValue ? 'Kg' : 'g'} ${formatNumeric}`;
  };

  const formatTableValue = (
    value: string,
    type?: 'currency' | 'weight' | 'meters'
  ) => {
    // Remove all non-digit characters except commas and periods
    const cleanedValue = value.replace(/[^\d.,]/g, '');

    // Replace periods with commas in the cleaned value
    const valueWithCommas = cleanedValue.replace(/\./g, ',');

    // Split the value with commas into integer and decimal parts
    const [integerPart, decimalPart] = valueWithCommas.split(',');

    // If there's no decimal part, add zeros to represent the decimal portion
    const formattedDecimalPart = decimalPart
      ? type === 'weight'
        ? decimalPart.slice(0, 3).padEnd(3, '0') // Force three digits for weight
        : decimalPart.slice(0, 2).padEnd(2, '0') // Limit decimal to two digits
      : type === 'weight'
      ? '000' // Force three zeros for weight
      : '00'; // Default to two zeros

    // Format the integer part with dots every three digits
    let formattedIntegerPart = integerPart
      .split('')
      .reverse()
      .map((char, index) => (index > 0 && index % 3 === 0 ? `${char}.` : char))
      .reverse()
      .join('');

    // Handle the case where the formattedIntegerPart starts with a dot
    if (formattedIntegerPart.startsWith('.')) {
      formattedIntegerPart = formattedIntegerPart.substr(1);
    }

    if (formattedIntegerPart === '' || formattedIntegerPart === null) {
      formattedIntegerPart = '0';
    }

    switch (type) {
      case 'currency':
        const formattedCurrency = `R$ ${formattedIntegerPart},${formattedDecimalPart}`;
        return formattedCurrency;
      case 'meters':
        const formattedMeters =
          Number(formattedIntegerPart) >= 1
            ? `m ${formattedIntegerPart},${formattedDecimalPart}`
            : `cm ${formattedIntegerPart},${formattedDecimalPart}`;
        return formattedMeters;
      case 'weight':
        const formattedWeight = `Kg ${formattedIntegerPart},${formattedDecimalPart}`;
        return formattedWeight;
      default:
        const formattedTotal = `${formattedIntegerPart},${formattedDecimalPart}`;
        return formattedTotal;
    }
  };

  return {
    formatInputValue,
    formatInputNotCurrency,
    tensPlace,
    formatHeightWidthMask,
    formatWeigth,
    formatTableValue,
  };
};

export { inputsMasks };
