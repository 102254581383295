import api from 'services/api';

import {
  IUpdateProps,
  CreateProductProps,
  IGetProductsAPIParams,
  IParamsCreateProduct,
  FileObject,
} from '../Types';

export const listPhotos = (etiquete: string) =>
  api.get(`/receipts/attachments?etiquette=${etiquete}`);

export const uploadImage = (fileList: FileList) => {
  const formData = new FormData();

  Array.from(fileList).forEach(file => {
    formData.append('file', file, file.name);
  });

  return api.post('/receipts/file/blob/multiple-file', formData);
};

export const createUploadFiles = (file: FileObject[], etiquette: string) => {
  const data = Object.values(file).map((obj: FileObject) => {
    return {
      filename: obj?.filename,
      size: obj?.size,
      type: obj?.type,
      url: obj?.url,
      etiquette,
    };
  });

  return api.post('/receipts/attachments/bulk', data);
};

export const updatePhoto = (photoId: string, is_default: boolean) =>
  api.put(`/receipts/attachments/${photoId}`, { is_default });

export const updatePhotoByEtiquette = (
  photoId: string,
  etiquette: string,
  is_default: boolean
) =>
  api.put(
    `receipts/attachments/updateByEtiquette?id=${photoId}&etiquette=${etiquette}`,
    { is_default }
  );

export const deletePhoto = (photoId: string) =>
  api.delete(`/receipts/attachments/${photoId}`);

export const getTypes = () => api.get('/receipts/types');

export const getCategories = (idFilter?: any) =>
  api.get('/receipts/categories', { params: idFilter });

export const getSubCategories = (idFilter?: any) =>
  api.get('/receipts/sub-categories', { params: idFilter });

export const getPhrases = () => api.get('/receipts/phrases');

export const getProductsAPI = (params: IGetProductsAPIParams) =>
  api.get('receipts/products', { params });

export const getEtiquetteValidateAPI = (etiquete?: string) => {
  return api.get(`receipts/products/validation?etiquette=${etiquete}`, {
    headers: { error: 'Verifique se essa etiqueta existe!' },
  });
};

export const deleteProduct = (productId: number) =>
  api.delete(`/receipts/products/${productId}`);

export const editProduct = (
  data: IParamsCreateProduct | CreateProductProps,
  productId: number
) => api.put(`/receipts/products/${productId}`, data);

export const updateOrder = (data: IUpdateProps) =>
  api.put(`receipts/${data.id}`, { ...data });

export const getConditionsAPI = () =>
  api.get(`/receipts/conditions?search=${null}&only_active=${true}`);

export const handleParameters = async (clientId?: number) => {
  const [categories, subCategories, phrares, users, types] = await Promise.all([
    api.get('/receipts/categories'),
    api.get('/receipts/sub-categories'),
    api.get('/receipts/phrases'),
    api.get(
      `receipts/users?sort=id&order=asc&search=&only_active=true&client_code=${clientId}`
    ),
    api.get('/receipts/types'),
  ]);

  return [categories, subCategories, phrares, users, types];
};

export const createProduct = (data: IParamsCreateProduct) =>
  api.post('/receipts/products', data);

export const importFileProductAPI = ({
  fileURL,
  receptId,
}: {
  fileURL: string;
  receptId: number;
}) =>
  api.post('receipts/io/products', { file_url: fileURL, receipt_id: receptId });
