import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useSnackbar } from 'notistack';
import { useGlobalLoading } from 'hooks/LoadingContext';

import { getCookie } from 'utils/cookies';
import { inputsMasks } from 'utils/inputsMasks';
import { transformStatus } from 'utils/transformStatus';

import { Table } from 'components/Tools/Table';
import TagStatus from 'components/Tools/TagStatus';

import EditIcon from 'assets/svg/editIcon.svg';

import { TableContainer } from 'app/WMS/styles/styles';

import { IReceivement } from 'utils/interfaces/receivement.types';

import { ITableProps } from '../Types/index';
import CustomizedImgCell from '../CustomizedImgCell';

import { adjustmentProducts } from '../Apis/index';

const TableProducts: React.FC<ITableProps> = ({
  paginateData,
  products,
  handleSearch,
  openCancelProps,
  currentSearch,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { formatTableValue } = inputsMasks();
  const { setGlobalLoading } = useGlobalLoading();
  const { formatStatusProduct } = transformStatus();

  const userId = getCookie('@pdamodules::id');

  const history = useHistory();

  const redirectProducts = (order: IReceivement) => {
    history.push({
      pathname: `/receivement/${order.id}`,
      state: {
        detailReceivement: order,
      },
    });
  };

  const addjustmentsOnProducts = async (etiquette: string) => {
    try {
      setGlobalLoading(true);

      await adjustmentProducts(etiquette, Number(userId))
        .then(res => {
          if (!res.data || res.data.length === 0) {
            return enqueueSnackbar(t('screens.commom.resultsNotFound'), {
              variant: 'warning',
            });
          }
          const receiptId = res.data[0][0].receipt;
          return redirectProducts(receiptId);
        })
        .catch(err => console.error(err));
    } catch (err) {
      console.error(err);
    } finally {
      setGlobalLoading(false);
    }
  };

  return (
    <>
      <TableContainer>
        <Table
          rows={products}
          defaultNumberOfRows={100}
          totalRecords={paginateData.total}
          defaultPage={paginateData.current_page - 1}
          onChangePage={page => handleSearch(page, currentSearch)}
          defaultSort="etiquette"
          columns={[
            {
              title: String(t('screens.invoiceReceivement.etiquetteLabel')),
              type: 'string',
              orderable: true,
              props: ['etiquette'],
              cssTitle: {
                width: '1%',
              },
              cssProps: {
                width: '10%',
              },
              renderItem: row => (
                <div className="code-item">
                  <CustomizedImgCell status={row.status} />
                  <p style={{ marginLeft: '10px' }}>
                    {String(row.etiquette || '')}
                  </p>
                </div>
              ),
            },
            {
              title: String(t('screens.commom.status')),
              type: 'string',
              props: ['status'],
              orderable: true,
              cssProps: {
                width: '1%',
              },
              renderItem: row => (
                <TagStatus
                  status={formatStatusProduct(row.status)}
                  width="fit-content"
                />
              ),
            },
            {
              title: String(t('screens.receivement.actions')),
              orderable: false,
              type: 'string',
              cssTitle: {
                width: '1%',
              },
              cssProps: {
                width: '0.2%',
              },
              props: ['etiquette'],
              renderItem: row => (
                <div className="row-actions-button-detail">
                  {row.status === 'DISAPROVED' && (
                    <button
                      type="button"
                      className="action"
                      onClick={() => addjustmentsOnProducts(row.etiquette)}
                    >
                      <img
                        src={EditIcon}
                        alt="Detalhes"
                        style={{ width: '30px' }}
                      />
                      <p className="hover-item">
                        {t('screens.approved.adjust')}
                      </p>
                    </button>
                  )}
                  {row.status === 'INTEGRATION' && (
                    <button
                      type="button"
                      className="action"
                      onClick={() => openCancelProps(row)}
                    >
                      <img
                        src={EditIcon}
                        alt="Detalhes"
                        style={{ width: '30px' }}
                      />
                      <p className="hover-item">
                        {t('screens.receivement.cancelOrder')}
                      </p>
                    </button>
                  )}
                </div>
              ),
            },
            {
              title: String(t('screens.publications.integationLabel')),
              type: 'string',
              orderable: true,
              props: ['integration_code'],
              cssProps: {
                width: '1%',
              },
              cssTitle: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.publications.receiveCode')),
              type: 'string',
              orderable: true,
              props: ['receive_code'],
              cssTitle: {
                width: '1%',
              },
              renderItem: row => (
                <div>
                  <p style={{ marginLeft: '10px' }}>
                    {row.receipt ? row.receipt.receive_code : ''}
                  </p>
                </div>
              ),
            },
            {
              title: String(t('screens.publications.codeOrigin')),
              type: 'string',
              orderable: true,
              props: ['code_receive_origin'],
              cssTitle: {
                width: '1%',
              },
              renderItem: row => (
                <div>
                  <p style={{ marginLeft: '10px' }}>
                    {row.receipt && row.receipt.code_receive_origin
                      ? row.receipt.code_receive_origin
                      : ''}
                  </p>
                </div>
              ),
            },
            {
              title: String(t('screens.commom.description')),
              type: 'string',
              orderable: true,
              props: ['description'],
              cssTitle: {
                width: '1%',
              },
              cssProps: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.pickingConference.qty')),
              type: 'number',
              orderable: true,
              props: ['quantity'],
              cssProps: {
                width: '10%',
              },
            },
            {
              title: String(t('screens.invoiceReceivement.comments')),
              type: 'string',
              orderable: true,
              props: ['note'],
              cssTitle: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.approved.reasonFailure')),
              type: 'string',
              orderable: true,
              props: ['disapproval_note'],
              cssTitle: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.commom.pallet')),
              type: 'string',
              orderable: true,
              props: ['pallet'],
              cssTitle: {
                width: '1%',
              },
              cssProps: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.invoiceReceivement.category')),
              type: 'string',
              orderable: true,
              props: ['category_id'],
              cssTitle: {
                width: '1%',
              },
              renderItem: row => (
                <div>
                  <p style={{ marginLeft: '10px' }}>
                    {row.category ? row.category.name : ''}
                  </p>
                </div>
              ),
            },
            {
              title: String(t('screens.invoiceReceivement.subcategory')),
              type: 'string',
              orderable: true,
              props: ['sub_category_id'],
              cssTitle: {
                width: '1%',
              },
              renderItem: row => (
                <div style={{ marginLeft: '10px' }}>
                  <p>{row.sub_category ? row.sub_category.name : ''}</p>
                </div>
              ),
            },
            {
              title: String(t('screens.invoiceReceivement.totalInitialValue')),
              type: 'string',
              orderable: true,
              props: ['total_initial_value'],
              cssTitle: {
                width: '1%',
              },
              renderItem: row => (
                <div>
                  <p style={{ marginLeft: '10px' }}>
                    {formatTableValue(
                      String(row.total_initial_value),
                      'currency'
                    )}
                  </p>
                </div>
              ),
            },
            {
              title: String(t('screens.invoiceReceivement.unitSaleValue')),
              type: 'string',
              orderable: true,
              props: ['unit_sale_value'],
              cssTitle: {
                width: '1%',
              },
              cssProps: {
                paddingLeft: '10px',
              },
              renderItem: row => (
                <div>
                  <p style={{ marginLeft: '10px' }}>
                    {row?.unit_sale_value === 0 || row?.unit_sale_value === null
                      ? ''
                      : formatTableValue(
                          String(row?.unit_sale_value),
                          'currency'
                        )}
                  </p>
                </div>
              ),
            },
            {
              title: String(t('screens.invoiceReceivement.totalSaleAmount')),
              type: 'string',
              orderable: true,
              props: ['total_sale_amount'],
              cssTitle: {
                width: '1%',
              },
              renderItem: row => (
                <div>
                  <p style={{ marginLeft: '10px' }}>
                    {row?.total_sale_amount === 0 ||
                    row?.total_sale_amount === null
                      ? ''
                      : formatTableValue(
                          String(row?.total_sale_amount),
                          'currency'
                        )}
                  </p>
                </div>
              ),
            },
            {
              title: String(t('screens.invoiceReceivement.initialUnitValue')),
              type: 'string',
              orderable: true,
              props: ['initial_unit_value'],
              cssTitle: {
                width: '1%',
              },
              renderItem: row => (
                <div>
                  <p style={{ marginLeft: '10px' }}>
                    {row?.initial_unit_value === 0 ||
                    row?.initial_unit_value === null
                      ? ''
                      : formatTableValue(
                          String(row?.initial_unit_value),
                          'currency'
                        )}
                  </p>
                </div>
              ),
            },
            {
              title: String(t('screens.invoiceReceivement.unitMarketValue')),
              type: 'string',
              orderable: true,
              props: ['unit_market_value'],
              cssTitle: {
                width: '1%',
              },
              renderItem: row => (
                <div>
                  <p style={{ marginLeft: '10px' }}>
                    {row?.unit_market_value === 0 ||
                    row?.unit_market_value === null
                      ? ''
                      : formatTableValue(
                          String(row?.unit_market_value),
                          'currency'
                        )}
                  </p>
                </div>
              ),
            },
            {
              title: String(t('screens.invoiceReceivement.totalMarketValue')),
              type: 'string',
              orderable: true,
              props: ['total_market_value'],
              cssTitle: {
                width: '1%',
              },
              cssProps: {
                width: '1%',
              },
              renderItem: row => (
                <div>
                  <p style={{ marginLeft: '10px' }}>
                    {row?.total_market_value === 0 ||
                    row?.total_market_value === null
                      ? ''
                      : formatTableValue(
                          String(row?.total_market_value),
                          'currency'
                        )}
                  </p>
                </div>
              ),
            },
            {
              title: String(t('screens.invoiceReceivement.originMarketValue')),
              type: 'string',
              orderable: true,
              props: ['origin_market_value'],
              cssTitle: {
                width: '1%',
              },
              renderItem: row => (
                <div>
                  <p style={{ marginLeft: '10px' }}>
                    {row?.origin_market_value}
                  </p>
                </div>
              ),
            },
          ]}
        />
      </TableContainer>
    </>
  );
};
export default TableProducts;
