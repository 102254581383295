import { format } from 'date-fns';

const formatDate = (value: Date | string) => {
  if (value) {
    const formatedDate = new Date(value)
      .toISOString()
      .split('T')[0]
      .split('-')
      .slice(0, 3)
      .reverse()
      .join('/');

    return formatedDate;
  }

  return '';
};

const formatDateHours = (date: Date) => {
  const formatedHours = format(new Date(date), 'hh:mm a');

  return formatedHours;
};
type FormatDateTypes = {
  currentDate: any;
  hasMinutesAndHoursAndSecouds?: boolean;
  formatHours?: true;
};

const formatToDate = ({
  currentDate,
  hasMinutesAndHoursAndSecouds,
  formatHours,
}: FormatDateTypes): string => {
  const localDate = new Date(currentDate);

  const dateFormatter = new Intl.DateTimeFormat('pt-br', {
    timeZone: 'UTC',
    year: 'numeric',
    day: '2-digit',
    month: '2-digit',
    minute: hasMinutesAndHoursAndSecouds ? '2-digit' : undefined,
    hour: hasMinutesAndHoursAndSecouds ? '2-digit' : undefined,
    second: hasMinutesAndHoursAndSecouds ? '2-digit' : undefined,
  });

  const formatedHours = localDate.toLocaleTimeString('pt-br', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'UTC',
  });

  return formatHours ? formatedHours : dateFormatter.format(localDate);
};

export { formatDate, formatDateHours, formatToDate };
