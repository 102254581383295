import { Form } from '@unform/web';
import styled, { css } from 'styled-components';

export const ModalContainer = styled(Form)`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(14, 14, 14, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;

  @media screen and (max-width: 1600px) {
    overflow-y: scroll;
  }
`;

export const ModalContainerDiv = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(14, 14, 14, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;

  @media screen and (max-width: 1600px) {
    overflow-y: scroll;
  }
`;

interface ModalButtonsProps {
  color?: string;
  bgColor?: string;
}

export const ModalButtons = styled.div<ModalButtonsProps>`
  display: flex;

  .actionsButton {
    height: 18px !important;
    background-color: ${props => props.bgColor};
    p {
      display: flex;
      align-items: center;
      font-size: 12px !important;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      color: ${props => `${props.color} !important` || '#fff'};

      &:hover {
        transition: 0.5s;
        opacity: 0.5;
      }
    }

    img {
      margin-right: 4px;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  height: fit-content;
  background: ${props => props.theme.colors.dashboard_card_bg};
  border-radius: 12px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 10px 15px 20px;

    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      color: ${props => props.theme.colors.container_title};
    }

    .footerButtons {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: flex-end;
      padding: 17px 20px 15px 20px;

      button {
        all: unset;
        cursor: pointer;

        display: flex;
        align-items: center;

        padding: 13px 16px;

        border-radius: 6px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 12px !important;
      }
      .submitButton {
        background: #04c8c8;
        color: #fff;

        &:hover {
          background: #dcfdfd;
          color: #04c8c8;
          transition: 0.5s;
        }
      }

      .backButton {
        background: ${props =>
          props.theme.title === 'light' ? `#eff2f5` : '#474761'};
        color: ${props =>
          props.theme.title === 'light' ? '#2b2b40' : '#eff2f5'};

        &:hover {
          transition: 0.5s;
          opacity: 0.7;
        }

        .icone {
          margin-right: 7px;
          fill: ${props =>
            props.theme.title === 'light' ? '#474761' : '#eff2f5'} !important;
        }

        img {
          padding-right: 7px;
        }
      }

      .buttonAction {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        padding: 12px 16px;
        background-color: ${props =>
          props.theme.title === 'light' ? '#E8FFF3' : '#1C3238'};

        p {
          margin-left: 10px;
          font-size: 12px !important;
          color: ${props =>
            props.theme.title === 'light' ? '#46be7d' : '#04aa77'} !important;
        }

        path {
          fill: ${props =>
            props.theme.title === 'light' ? '#46be7d' : '#04AA77'};
        }

        &:hover {
          background-color: #46be7d;
          transition: 0.5s;
          box-shadow: none;
          p {
            color: #e8fff3 !important;
          }
          path {
            fill: #e8fff3;
          }
        }
      }

      @media screen and (max-width: 581px) {
        flex-direction: column;
        align-items: center;

        & > div {
          order: 1;
          padding-bottom: 10px;
        }

        .footerButtons {
          order: 2;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: flex-start;
          justify-content: center;
          width: 100%;

          button {
            padding: 13px 16px;
          }

          .backButton {
            margin-right: 10px;
          }
        }
      }
    }
  }
`;

interface ModalContentContainerProps {
  buttonBackground?: string;
  submitButtonHover?: string;
  submitButtonTitleColor?: string;
  inputsResponsive?: boolean;
}

export const ModalContentContainer = styled.div<ModalContentContainerProps>`
  max-width: 1200px;
  margin: 0 1rem;
  width: 100%;
  height: fit-content;
  max-height: 890.15px;
  background: ${props => props.theme.colors.dashboard_card_bg};
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;

  ${props =>
    props.inputsResponsive &&
    css`
      @media screen and (max-width: 1281px) {
        .switchContainer {
          border-top: 1px solid rgba(28, 28, 28, 0.4);
          margin: 20px 0 10px !important;
          box-sizing: border-box;
          margin-top: 20px !important;
          padding-top: 10px !important;

          .MuiIconButton-label:first-child {
            margin-left: 20px;
          }
        }

        .MuiGrid-container {
          width: 100%;
          height: 690.15px;
          box-sizing: border-box;
          overflow-y: scroll;
          overflow-x: hidden;
        }
      }

      @media screen and (max-width: 600px) {
        .switchContainer {
          box-sizing: border-box;
          display: block !important;
          margin-top: 20px !important;
        }
        .MuiGrid-container {
          width: 100%;
          display: block;
          .MuiGrid-item {
            max-width: 600px !important;
            width: 100% !important;
          }
        }
      }
    `}

  .labelInput {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    color: ${props => (props.theme.title === 'light' ? '#464e5f' : '#b5b5c3')};
  }

  .inputDesc {
    font-weight: 400;
    font-size: 12px;
    margin-left: 0.5rem;
    color: #b5b5c3;
  }

  .sel__menu {
    background: ${props => props.theme.colors.input};
    color: ${props => props.theme.colors.select_text};

    .sel__menu-list {
      font-size: 13px;
      .sel__option {
        color: ${props => props.theme.colors.select_text};
      }
      .sel__option--is-selected {
        background: ${props => props.theme.colors.primary};
        color: #ffffff;
      }

      .sel__option--is-focused {
        background: ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.text};
      }
    }
  }

  .input-wrapper {
    input {
      padding: 2px 8px;
    }
  }

  .MuiBadge-badge {
    color: red;
    margin: 5px -4px;
  }

  .MuiBadge-anchorOriginTopRightRectangle {
    font-size: 13px;
    z-index: initial;
  }

  .modalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 10px 15px 20px;
    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;

      color: ${props => props.theme.colors.container_title};
    }

    .modalCloseButton {
      transition: 0.15s ease;
      &:hover {
        background-color: ${props => props.theme.colors.body};
      }
    }
  }
  .modalFooter {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    padding: 15px 30px 15px 15px;

    button {
      padding: 14px;
      border-radius: 6px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      text-transform: none !important;
    }
    .closeModal {
      background: #0095e8;
      color: #fff5f8;

      &:hover {
        background: #3fbaff;
      }
    }
    .newButton {
      background: #ecf8ff;
      color: #0095e8;

      &:hover {
        background: #0095e8;
        p {
          color: #fff5f8;
        }
        path {
          fill: #fff5f8;
        }
      }
    }

    .printerButton {
      background-color: ${props =>
        props.theme.title === 'dark' ? '#212E48' : '#ecf8ff'};
      color: #0095e8;
      &:hover {
        background: #3fbaff;
        transition: 0.5s;
        color: #fff !important;
      }
    }
    .submitModal {
      background: ${props => {
        const color = props.buttonBackground || '#46be7d';
        return color;
      }};
      color: #fff5f8;

      svg {
        margin-right: 7px;
      }

      &:hover {
        background: ${props =>
          props.theme.title === 'light' ? '#e8fff3' : '#1C3238'};
        path {
          fill: ${props =>
            props.theme.title === 'light' ? '#46be7d' : '#04AA77'};
        }
        p {
          color: ${props =>
            props.theme.title === 'light' ? '#46be7d' : '#04AA77'};
        }
      }
    }
  }
`;
