import React, { useRef } from 'react';
import i18n from 'i18next';
import * as Yup from 'yup';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { FormHandles } from '@unform/core';

import MD5 from 'crypto-js/md5';
import Base64 from 'crypto-js/enc-base64';

import { getCookie } from 'utils/cookies';
import getValidationErrors from 'utils/getValidationErrors';

import { useGlobalLoading } from 'hooks/LoadingContext';

import PageMode from 'components/Tools/PageMode';

import { authUserAPI, cancelProductClientAPI, editProduct } from '../Apis';

import {
  IAuthClient,
  ICancelProductsProps,
  IParamsCancelProducts,
  IParamsUpdateProduct,
  IResponseAuth,
} from '../Types';

const CancelProducts: React.FC<ICancelProductsProps> = ({
  isOpen,
  product,
  reasons,
  handleClose,
  updateProduct,
}) => {
  const { t } = useTranslation();
  const { setGlobalLoading } = useGlobalLoading();
  const { enqueueSnackbar } = useSnackbar();
  const searchRef = useRef<FormHandles>(null);

  const idCliente = getCookie('@pdamodules::codigoCliente');
  const environment_type = String(process.env.REACT_APP_ENV);

  const translatorMsg = (msg: string, productId?: number): string => {
    const translator = i18n.t;
    return productId
      ? `${String(productId)} ${translator(msg)}`
      : `${translator(msg)}`;
  };

  // const filterReason = (reasonId: number): boolean => {
  //   const res = reasons.find(r => r.value === reasonId);

  //   return (
  //     res?.label === 'Lançamento incorreto' || res?.label === 'Produto removido'
  //   );
  // };

  const enviroment =
    environment_type !== 'dev'
      ? {
          urlPDA: 'https://api.pdahub.com.br/api/Usuario/SuperBid',
          urlClient:
            'https://sso.superbid.net/auth/realms/sbws/protocol/openid-connect/token',
          urlClientUpdate: 'https://external-connector.superbid.net/product/',
          clientSecret: '1ZGgHMxmaWFdAnzzChgR09T0BXJgKcBP',
        }
      : {
          urlPDA: 'https://api.stage.pdahub.com.br/api/Usuario/SuperBid',
          urlClient:
            'https://sso.stage.superbid.net/auth/realms/sbws/protocol/openid-connect/token',
          urlClientUpdate:
            'https://external-connector.stage.superbid.net/product/',
          clientSecret: 'fthGGWC80W4y8Vw7PuaEqPDIvT5Y3cbm',
        };

  const handleSubmit = async (data: IParamsCancelProducts) => {
    setGlobalLoading(true);
    try {
      const schema = Yup.object().shape({
        reason: Yup.number().required(),
        user: Yup.string().required(),
        password: Yup.string().required(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const authData: IAuthClient = {
        codigoCliente: +idCliente,
        usuario: data.user,
        senha: Base64.stringify(MD5(data.password)),
        urlApiCliente: enviroment.urlClient,
        gratType: 'client_credentials',
        clientId: 'pda-core-api',
        clientSecret: enviroment.clientSecret,
      };

      const auth = await authUserAPI(enviroment.urlPDA, authData);

      if (auth && auth.data.lider === true) {
        const responseAuthenticate: IResponseAuth = auth.data;

        const paramsUpdateData: IParamsUpdateProduct = {
          // statusId: filterReason(data.reason) ? '2' : '5',
          statusId: '5',
          statusReasonId: data.reason,
        };

        const statusApiCliente = await cancelProductClientAPI(
          enviroment.urlClientUpdate,
          product.integration_code,
          paramsUpdateData,
          responseAuthenticate
        ).catch(() => {
          enqueueSnackbar(
            `Erro em requisição externa: The product ${product.integration_code} does not have DISPONIVEL status and DIGITALIZADO status reason. Cannot update product.`,
            {
              variant: 'error',
            }
          );
        });

        if (statusApiCliente && statusApiCliente.status === 200) {
          await editProduct(product.integration_code).then(res => {
            if (res.data) {
              updateProduct(product.integration_code);
              enqueueSnackbar(
                translatorMsg(
                  'screens.invoiceReceivement.cancelSuccessfully',
                  product.id
                ),
                {
                  variant: 'success',
                }
              );
              handleClose();
            }
          });
        }
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        searchRef.current?.setErrors(errors);
        enqueueSnackbar(translatorMsg('screens.invoiceReceivement.errorForm'), {
          variant: 'warning',
        });
      }
    } finally {
      setGlobalLoading(false);
    }
  };

  return (
    <>
      <PageMode
        isModal
        open={isOpen}
        title={String(t('components.confirmationModal.cancel'))}
        submitButtonIcon
        modalRefObject={searchRef}
        submitButtonTitle={String(t('screens.commom.saveButtonTitle'))}
        handleSubmit={(data: IParamsCancelProducts) => handleSubmit(data)}
        handleClose={() => handleClose()}
        inputs={[
          {
            name: 'reason',
            isRequired: true,
            label: String(t('screens.approved.reason')),
            placeholder: String(t('screens.commom.selectReason')),
            type: 'select',
            options: reasons,
            xl: 12,
            xs: 12,
            lg: 12,
          },
          {
            name: 'user',
            required: true,
            label: String(t('screens.commom.user')),
            placeholder: String(t('screens.commom.selectReason')),
            type: 'text',
            isAutoComplete: false,
            xl: 6,
            xs: 6,
            lg: 6,
          },
          {
            name: 'password',
            isRequired: true,
            label: String(t('screens.commom.password')),
            placeholder: String(t('screens.commom.password')),
            type: 'password',
            isAutoComplete: false,
            xl: 6,
            xs: 6,
            lg: 6,
          },
        ]}
      />
    </>
  );
};
export default CancelProducts;
