import React from 'react';

import IconCaixaCancelado from 'assets/svg/caixa-preta.svg';
import Icon from 'components/Tools/Icon';
import { CustomizedImgCellContainer } from 'features/DetailReceivement/Styles/styles';

interface ICustomizedImgCellProps {
  status: string;
}

const CustomizedImgCell: React.FC<ICustomizedImgCellProps> = ({ status }) => {
  const handleColor = (stats: string) => {
    switch (stats) {
      case 'PENDING':
        return '#A1A5B7';
      case 'APPROVED':
      case 'FINISHED':
      case 'Finalizado Aprovação':
        return '#46BE7D';
      case 'DISAPROVED':
        return '#D9214E';
      case 'PUBLISHED':
      case 'ADJUSTMENT':
      case 'AWAITING_INTEGRATION':
        return '#F69B11';
      default:
        return '#46BE7D';
    }
  };

  return status !== '' ? (
    <CustomizedImgCellContainer>
      <Icon icon={IconCaixaCancelado} color={handleColor(status)} />
    </CustomizedImgCellContainer>
  ) : (
    <></>
  );
};

export default CustomizedImgCell;
