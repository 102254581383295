/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Badge,
  Button,
  Divider,
  Fade,
  Grid,
  ClickAwayListener,
} from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import React, { useCallback, ReactElement, useEffect } from 'react';
import backArrow from 'assets/svg/backArrow.svg';
import { useMobile } from 'hooks/Mobile';
import {
  Checkbox,
  DatePicker,
  Input,
  Select,
  SwitchButton,
} from 'components/Tools/Form';
import { InputProps } from 'components/Tools/Search';
import {
  Container,
  ModalContainer,
  ModalContainerDiv,
  ModalContentContainer,
} from './styles';
import Loader from '../Loader';

type newButtonActionsProps = {
  onClick: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined;
  renderItem: () => string | ReactElement;
};

interface PageModeProps {
  isModal?: boolean;
  open?: boolean;
  onPage?: boolean;
  newPage?: boolean;
  pageActionsFooter?: boolean;
  actionPrintButton?: (data?: any) => void;
  buttonPrint?: boolean;
  children?: any;
  title?: string;
  submitButtonTitle?: string;
  submitButtonColor?: string;
  submitButtonHover?: string;
  submitButtonTitleHover?: string;
  inLineChildren?: boolean;
  submitButtonIcon?: boolean;
  inputs?: InputProps[];
  modalRefObject?: React.RefObject<FormHandles>;
  modalLoading?: boolean;
  handleClose?: () => void;
  disableSubmit?: boolean;
  handleSubmit?(data: object): void;
  newButton?: boolean;
  newButtonActions?: Array<newButtonActionsProps>;
  closeWhenClickOutside?: boolean;
  initialData?: any;
  buttonsActions?: boolean;
  buttonsActionsTitle?: string;
  buttonActionFunc?: () => void;
  inputsResponsive?: boolean;
  noForm?: boolean;
}

const PageMode: React.FC<PageModeProps> = ({
  isModal,
  onPage,
  newPage,
  pageActionsFooter,
  buttonPrint,
  actionPrintButton,
  open,
  handleClose = () => {},
  children,
  inLineChildren,
  submitButtonTitle,
  submitButtonColor,
  submitButtonIcon,
  handleSubmit = () => {},
  disableSubmit,
  modalLoading,
  title,
  inputs,
  modalRefObject,
  newButton,
  newButtonActions,
  closeWhenClickOutside,
  initialData,
  buttonsActions,
  buttonsActionsTitle,
  buttonActionFunc,
  inputsResponsive,
  noForm,
}) => {
  const { colors } = useTheme();
  const { isMobileDevice } = useMobile();

  const { t } = useTranslation();

  const ownRef = React.useRef<FormHandles>(null);
  const formRef = modalRefObject || ownRef;

  const switchType = (input: InputProps) => {
    switch (input.type) {
      case 'text':
        return (
          <Input
            id={input.name}
            name={input.name}
            key={input.name.toString()}
            type={input.type || 'text'}
            isDisabled={input.isDisabled}
            placeholder={input.placeholder}
            handleInputError={handleInputError}
            required={input.isRequired}
            readOnly={input.readOnly}
            value={input.value}
            maxLength={input.maxLength}
            onChange={input.onChange}
            isAutoComplete={input.isAutoComplete}
          />
        );
      case 'password':
        return (
          <Input
            id={input.name}
            name={input.name}
            key={input.name.toString()}
            type="password"
            isDisabled={input.isDisabled}
            placeholder={input.placeholder}
            handleInputError={handleInputError}
            defaultValue={input.defaultValue}
            value={input.value}
            onChange={input.onChange}
            isAutoComplete={input.isAutoComplete}
          />
        );
      case 'number':
        return (
          <Input
            id={input.name}
            name={input.name}
            key={input.name.toString()}
            type="number"
            isDisabled={input.isDisabled}
            placeholder={input.placeholder}
            handleInputError={handleInputError}
            required={input.isRequired}
            onChange={input.onChange}
            value={input.value}
            isAutoComplete={input.isAutoComplete}
          />
        );
      case 'select':
        return (
          <Select
            name={input.name}
            key={input.name.toString()}
            options={input.options}
            placeholder={input.placeholder}
            handleInputError={handleInputError}
            isLoading={input.isLoading}
            isDisabled={input.isDisabled}
            styles={input.styles}
            onChangeEvent={input.onChangeEvent}
            isApiSearch={input.isApiSearch}
            required={input.isRequired}
            handleInputChange={input.handleInputChange}
            // isMulti={isMulti}
          />
        );
      case 'date':
        return (
          <DatePicker
            id={input.name}
            name={input.name}
            type="date"
            key={input.name.toString()}
            isDisabled={input.isDisabled}
            placeholder={input.placeholder}
            handleInputError={handleInputError}
            required={input.isRequired}
            dateMin={input.minDate}
          />
        );
      case 'switch':
        return (
          <SwitchButton
            id={input.name}
            name={input.name}
            key={input.name.toString()}
            type="checkbox"
            placeholder={input.placeholder}
            required={input.isRequired}
          />
        );
      case 'checkbox':
        return (
          <Checkbox
            id={input.name}
            name={input.name}
            type="checkbox"
            key={input.name.toString()}
            label={input.labelCheckbox}
            placeholder={input.placeholder}
            onClickEvent={input.onClickEvent}
            required={input.isRequired}
          />
        );
      default:
        return <input type="hidden" />;
    }
  };

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    if (open) {
      window.addEventListener('keyup', handleKeyPress, true);
      return () => window.removeEventListener('keyup', handleKeyPress, true);
    }
    return () => window.removeEventListener('keyup', handleKeyPress, true);
  }, [handleKeyPress, open]);

  const handleInputError = useCallback(
    ({ inputName, message }) => {
      if (formRef) {
        formRef.current?.setFieldError(inputName, message);
      }
    },
    [formRef]
  );

  return (
    <>
      {isModal && (
        <>
          <Fade
            unmountOnExit
            mountOnEnter
            timeout={400}
            in={open}
            style={{ height: isMobileDevice ? 'fit-content' : '' }}
          >
            <ModalContainer
              ref={formRef}
              initialData={initialData}
              onSubmit={data => handleSubmit(data)}
              style={{ height: isMobileDevice ? 'fit-content' : '' }}
              id="pageMode"
            >
              {modalLoading ? (
                <Loader />
              ) : (
                <ClickAwayListener
                  onClickAway={() => {
                    if (closeWhenClickOutside) {
                      handleClose();
                    }
                  }}
                >
                  <ModalContentContainer
                    buttonBackground={submitButtonColor}
                    inputsResponsive={inputsResponsive}
                    style={{
                      height: isMobileDevice ? 'fit-content' : '',
                    }}
                  >
                    <>
                      <div className="modalHeader">
                        <p>{title}</p>
                        <Button
                          onClick={handleClose}
                          style={{ color: colors.container_title }}
                          className="modalCloseButton"
                        >
                          <CloseOutlinedIcon color="inherit" />
                        </Button>
                      </div>
                      <Divider color="#EBEDF2" />
                      <Grid
                        container
                        spacing={3}
                        style={{
                          padding: '1.5rem 1rem',
                          alignItems: 'flex-start',
                          maxHeight: '700px',
                          overflowY: 'auto',
                          overflowX: 'hidden',
                        }}
                      >
                        {inputs?.map(
                          ({
                            name,
                            label,
                            type,
                            options,
                            placeholder,
                            width = 3,
                            sm = 12,
                            xl = width,
                            lg = width,
                            xs = 12,
                            md = 12,
                            isRequired,
                            isLoading,
                            isDisabled,
                            styles,
                            onClickEvent,
                            labelCheckbox,
                            onChangeEvent,
                            isMulti,
                            messageInputs,
                            minDate,
                            ...rest
                          }) => (
                            <Grid
                              item
                              xl={xl}
                              lg={lg}
                              xs={xs}
                              md={md}
                              sm={sm}
                              key={name.toString()}
                            >
                              {(!!label && isRequired) || rest.required ? (
                                <Badge badgeContent="*">
                                  <p className="labelInput">{label}</p>
                                </Badge>
                              ) : (
                                <p className="labelInput">{label}</p>
                              )}
                              {switchType({
                                name,
                                label,
                                type,
                                options,
                                placeholder,
                                width,
                                sm,
                                xl,
                                lg,
                                xs,
                                md,
                                isRequired,
                                isLoading,
                                isDisabled,
                                styles,
                                onClickEvent,
                                labelCheckbox,
                                onChangeEvent,
                                isMulti,
                                minDate,
                                ...rest,
                              })}
                              <div>
                                {messageInputs && (
                                  <p className="inputDesc">{messageInputs}</p>
                                )}
                              </div>
                            </Grid>
                          )
                        )}
                        {inLineChildren && children}
                      </Grid>
                      {!inLineChildren && children}
                      <Divider color="#EBEDF2" />
                      <div className="modalFooter">
                        {disableSubmit !== true && (
                          <Button type="submit" className="submitModal">
                            {submitButtonIcon && (
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.55333 2H9.78C10.2667 2 10.6667 2.4 10.6667 2.88667V4.44667C10.6667 4.93333 10.2667 5.33333 9.78 5.33333H9.55333C9.06667 5.33333 8.66667 4.93333 8.66667 4.44667V2.88667C8.66667 2.4 9.06667 2 9.55333 2ZM16 3.03333V14.0867C16 15.1467 15.14 16 14.0867 16H1.91333C0.86 16 0 15.14 0 14.0867V2.18667C0 0.98 0.98 0 2.18667 0H12.9667C13.2 0 13.4267 0.0933333 13.5933 0.26L15.74 2.40667C15.9067 2.57333 16 2.8 16 3.03333ZM5.55333 6H10.4467C10.9333 6 11.3333 5.6 11.3333 5.11333V2.22C11.3333 1.73333 10.9333 1.33333 10.4467 1.33333H5.55333C5.06667 1.33333 4.66667 1.73333 4.66667 2.22V5.11333C4.66667 5.6 5.06667 6 5.55333 6ZM14.6667 3.58667C14.6667 3.35333 14.5733 3.12667 14.4067 2.96L12.78 1.33333H12.6667V6.44667C12.6667 6.93333 12.2667 7.33333 11.78 7.33333H4.22C3.73333 7.33333 3.33333 6.93333 3.33333 6.44667V2.22C3.33333 1.73333 2.93333 1.33333 2.44667 1.33333H2.22C1.73333 1.33333 1.33333 1.73333 1.33333 2.22V13.78C1.33333 14.2667 1.73333 14.6667 2.22 14.6667H13.78C14.2667 14.6667 14.6667 14.2667 14.6667 13.78V3.58667Z"
                                  fill="#E8FFF3"
                                />
                              </svg>
                            )}
                            <p>{submitButtonTitle}</p>
                          </Button>
                        )}
                        {newButton &&
                          newButtonActions?.map(
                            (action: newButtonActionsProps) => (
                              <Button
                                type="button"
                                onClick={action.onClick}
                                className="newButton"
                              >
                                {/* @ts-ignore */}
                                {action.renderItem()}
                              </Button>
                            )
                          )}
                        {buttonPrint && (
                          <Button
                            className="printerButton"
                            onClick={actionPrintButton}
                          >
                            {t('components.pageMode.printOut')}
                          </Button>
                        )}
                        <Button onClick={handleClose} className="closeModal">
                          {t('components.pageMode.close')}
                        </Button>
                      </div>
                    </>
                  </ModalContentContainer>
                </ClickAwayListener>
              )}
            </ModalContainer>
          </Fade>
        </>
      )}
      {onPage && (
        <>
          <Grid
            container
            spacing={3}
            style={{
              padding: '1.5rem 1rem',
              alignItems: 'self-end',
            }}
          >
            {inputs?.map(
              ({
                name,
                label,
                type = 'text',
                options,
                placeholder,
                width = 3,
                sm = 12,
                xl = width,
                lg = width,
                xs = 12,
                md = 12,
                isRequired,
                isLoading,
                isDisabled,
                styles,
                onClickEvent,
                labelCheckbox,
                onChangeEvent,
                isMulti,
                ...rest
              }) => (
                <Grid
                  item
                  xl={xl}
                  lg={lg}
                  xs={xs}
                  md={md}
                  sm={sm}
                  key={name.toString()}
                >
                  {!!label && isRequired ? (
                    <Badge badgeContent="*">
                      <p className="labelInput">{label}</p>
                    </Badge>
                  ) : (
                    <p className="labelInput">{label}</p>
                  )}
                  {switchType({
                    name,
                    label,
                    type,
                    options,
                    placeholder,
                    width,
                    sm,
                    xl,
                    lg,
                    xs,
                    md,
                    isRequired,
                    isLoading,
                    isDisabled,
                    styles,
                    onClickEvent,
                    labelCheckbox,
                    onChangeEvent,
                    isMulti,
                    ...rest,
                  })}
                </Grid>
              )
            )}
          </Grid>
          {children}
        </>
      )}
      {newPage && (
        <Container>
          <div className="header">
            <p>{title}</p>
            {!pageActionsFooter && (
              <div className="footerButtons">
                <Button className="backButton" onClick={handleClose}>
                  <svg
                    className="icone"
                    width="16"
                    height="13"
                    viewBox="0 0 16 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M5.75499 0.191131C5.75499 0.191131 1.75388 4.1958 0.195566 5.755C0.0648925 5.88478 0 6.05546 0 6.22613C0 6.39681 0.0648925 6.56749 0.195566 6.69727C1.75299 8.25647 5.75321 12.2594 5.75321 12.2594C5.8821 12.3883 6.05189 12.4523 6.22168 12.4523C6.39147 12.4514 6.56214 12.3865 6.69282 12.2558C6.95328 11.9953 6.95416 11.5749 6.69637 11.3162L2.27302 6.89284H15.3333C15.7013 6.89284 16 6.59415 16 6.22613C16 5.85811 15.7013 5.55943 15.3333 5.55943H2.27302L6.69815 1.13341C6.95505 0.876502 6.95328 0.456924 6.69282 0.196465C6.56214 0.0657907 6.39147 9.13319e-06 6.22168 9.13319e-06C6.05189 -0.000879805 5.88388 0.0631239 5.75499 0.191131Z" />
                  </svg>
                  {t('components.pageMode.back')}
                </Button>
                {buttonsActions && (
                  <Button className="buttonAction" onClick={buttonActionFunc}>
                    <svg
                      width="15"
                      height="14"
                      viewBox="0 0 15 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.12603 0.39072L3.76283 2.88486C2.83386 3.57324 2.60347 4.93614 3.24831 5.92812L6.74925 11.319C7.54419 12.5433 8.81968 13.2 10.1162 13.2C12.3429 13.2 14.2064 11.2735 14.2064 8.82156C14.2064 7.96092 13.9692 7.0917 13.4763 6.33138L9.97533 0.9405C9.57755 0.32868 8.94012 0 8.29157 0C7.88885 0 7.48181 0.12672 7.12603 0.39072ZM7.70973 1.28898C8.1736 0.94512 8.81165 1.06854 9.13469 1.56354L10.0087 2.91126L7.59237 4.68864L6.13468 2.44266L7.70973 1.28898ZM11.8635 11.521C10.4726 12.5525 8.55532 12.1823 7.5899 10.696L5.54789 7.55172L10.5924 3.81084L12.6356 6.95508C13.6004 8.4414 13.2539 10.4894 11.8635 11.521ZM1.96171 3.88674L0.418161 2.73834L0.801115 2.15028L2.20384 3.1944C2.09945 3.41352 2.01854 3.64386 1.96171 3.88674ZM2.39532 6.5307L0.975297 7.5966L0.589873 7.01052L2.08092 5.88918C2.16431 6.11226 2.2656 6.32808 2.39532 6.5307ZM1.91477 5.27274H0V4.55664H1.87092C1.86289 4.7982 1.874 5.03778 1.91477 5.27274Z"
                        fill="#46BE7D"
                      />
                    </svg>
                    <p>{buttonsActionsTitle}</p>
                  </Button>
                )}
                {disableSubmit !== true && (
                  <Button
                    type="button"
                    className="submitButton"
                    onClick={handleSubmit}
                  >
                    {submitButtonIcon && (
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.55333 2H9.78C10.2667 2 10.6667 2.4 10.6667 2.88667V4.44667C10.6667 4.93333 10.2667 5.33333 9.78 5.33333H9.55333C9.06667 5.33333 8.66667 4.93333 8.66667 4.44667V2.88667C8.66667 2.4 9.06667 2 9.55333 2ZM16 3.03333V14.0867C16 15.1467 15.14 16 14.0867 16H1.91333C0.86 16 0 15.14 0 14.0867V2.18667C0 0.98 0.98 0 2.18667 0H12.9667C13.2 0 13.4267 0.0933333 13.5933 0.26L15.74 2.40667C15.9067 2.57333 16 2.8 16 3.03333ZM5.55333 6H10.4467C10.9333 6 11.3333 5.6 11.3333 5.11333V2.22C11.3333 1.73333 10.9333 1.33333 10.4467 1.33333H5.55333C5.06667 1.33333 4.66667 1.73333 4.66667 2.22V5.11333C4.66667 5.6 5.06667 6 5.55333 6ZM14.6667 3.58667C14.6667 3.35333 14.5733 3.12667 14.4067 2.96L12.78 1.33333H12.6667V6.44667C12.6667 6.93333 12.2667 7.33333 11.78 7.33333H4.22C3.73333 7.33333 3.33333 6.93333 3.33333 6.44667V2.22C3.33333 1.73333 2.93333 1.33333 2.44667 1.33333H2.22C1.73333 1.33333 1.33333 1.73333 1.33333 2.22V13.78C1.33333 14.2667 1.73333 14.6667 2.22 14.6667H13.78C14.2667 14.6667 14.6667 14.2667 14.6667 13.78V3.58667Z"
                          fill="#E8FFF3"
                        />
                      </svg>
                    )}
                    <p>{submitButtonTitle}</p>
                  </Button>
                )}
              </div>
            )}
          </div>
          <Divider color="#EBEDF2" />
          {children}
          {pageActionsFooter && (
            <div className="footerButtons">
              <Button className="backButton" onClick={handleClose}>
                <img alt="Voltar" src={backArrow} />
                Voltar
              </Button>
              {disableSubmit !== true && (
                <Button
                  type="button"
                  disableRipple
                  className="submitButton"
                  onClick={handleSubmit}
                >
                  {submitButtonIcon && (
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.55333 2H9.78C10.2667 2 10.6667 2.4 10.6667 2.88667V4.44667C10.6667 4.93333 10.2667 5.33333 9.78 5.33333H9.55333C9.06667 5.33333 8.66667 4.93333 8.66667 4.44667V2.88667C8.66667 2.4 9.06667 2 9.55333 2ZM16 3.03333V14.0867C16 15.1467 15.14 16 14.0867 16H1.91333C0.86 16 0 15.14 0 14.0867V2.18667C0 0.98 0.98 0 2.18667 0H12.9667C13.2 0 13.4267 0.0933333 13.5933 0.26L15.74 2.40667C15.9067 2.57333 16 2.8 16 3.03333ZM5.55333 6H10.4467C10.9333 6 11.3333 5.6 11.3333 5.11333V2.22C11.3333 1.73333 10.9333 1.33333 10.4467 1.33333H5.55333C5.06667 1.33333 4.66667 1.73333 4.66667 2.22V5.11333C4.66667 5.6 5.06667 6 5.55333 6ZM14.6667 3.58667C14.6667 3.35333 14.5733 3.12667 14.4067 2.96L12.78 1.33333H12.6667V6.44667C12.6667 6.93333 12.2667 7.33333 11.78 7.33333H4.22C3.73333 7.33333 3.33333 6.93333 3.33333 6.44667V2.22C3.33333 1.73333 2.93333 1.33333 2.44667 1.33333H2.22C1.73333 1.33333 1.33333 1.73333 1.33333 2.22V13.78C1.33333 14.2667 1.73333 14.6667 2.22 14.6667H13.78C14.2667 14.6667 14.6667 14.2667 14.6667 13.78V3.58667Z"
                        fill="#E8FFF3"
                      />
                    </svg>
                  )}
                  <p>{submitButtonTitle}</p>
                </Button>
              )}
            </div>
          )}
        </Container>
      )}
      {noForm && (
        <>
          <Fade
            unmountOnExit
            mountOnEnter
            timeout={400}
            in={open}
            style={{ height: isMobileDevice ? 'fit-content' : '' }}
          >
            <ModalContainerDiv
              style={{ height: isMobileDevice ? 'fit-content' : '' }}
              id="pageMode"
            >
              {modalLoading ? (
                <Loader />
              ) : (
                <ClickAwayListener
                  onClickAway={() => {
                    if (closeWhenClickOutside) {
                      handleClose();
                    }
                  }}
                >
                  <ModalContentContainer
                    buttonBackground={submitButtonColor}
                    inputsResponsive={inputsResponsive}
                    style={{ height: isMobileDevice ? 'fit-content' : '' }}
                  >
                    <>
                      <div className="modalHeader">
                        <p>{title}</p>
                        <Button
                          onClick={handleClose}
                          style={{ color: colors.container_title }}
                          className="modalCloseButton"
                        >
                          <CloseOutlinedIcon color="inherit" />
                        </Button>
                      </div>
                      <Divider color="#EBEDF2" />
                      <Grid
                        container
                        spacing={3}
                        style={{
                          padding: '1.5rem 1rem',
                          alignItems: 'flex-start',
                        }}
                      >
                        {inputs?.map(
                          ({
                            name,
                            label,
                            type,
                            options,
                            placeholder,
                            width = 3,
                            sm = 12,
                            xl = width,
                            lg = width,
                            xs = 12,
                            md = 12,
                            isRequired,
                            isLoading,
                            isDisabled,
                            styles,
                            onClickEvent,
                            labelCheckbox,
                            onChangeEvent,
                            isMulti,
                            messageInputs,
                            ...rest
                          }) => (
                            <Grid
                              item
                              xl={xl}
                              lg={lg}
                              xs={xs}
                              md={md}
                              sm={sm}
                              key={name.toString()}
                            >
                              {!!label && isRequired ? (
                                <Badge badgeContent="*">
                                  <p className="labelInput">{label}</p>
                                </Badge>
                              ) : (
                                <p className="labelInput">{label}</p>
                              )}
                              {switchType({
                                name,
                                label,
                                type,
                                options,
                                placeholder,
                                width,
                                sm,
                                xl,
                                lg,
                                xs,
                                md,
                                isRequired,
                                isLoading,
                                isDisabled,
                                styles,
                                onClickEvent,
                                labelCheckbox,
                                onChangeEvent,
                                isMulti,
                                ...rest,
                              })}
                              <div>
                                {messageInputs && (
                                  <p className="inputDesc">{messageInputs}</p>
                                )}
                              </div>
                            </Grid>
                          )
                        )}
                        {inLineChildren && children}
                      </Grid>
                      {!inLineChildren && children}
                      <Divider color="#EBEDF2" />
                      <div className="modalFooter">
                        {disableSubmit !== true && (
                          <Button
                            type="submit"
                            className="submitModal"
                            onClick={handleSubmit}
                          >
                            {submitButtonIcon && (
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.55333 2H9.78C10.2667 2 10.6667 2.4 10.6667 2.88667V4.44667C10.6667 4.93333 10.2667 5.33333 9.78 5.33333H9.55333C9.06667 5.33333 8.66667 4.93333 8.66667 4.44667V2.88667C8.66667 2.4 9.06667 2 9.55333 2ZM16 3.03333V14.0867C16 15.1467 15.14 16 14.0867 16H1.91333C0.86 16 0 15.14 0 14.0867V2.18667C0 0.98 0.98 0 2.18667 0H12.9667C13.2 0 13.4267 0.0933333 13.5933 0.26L15.74 2.40667C15.9067 2.57333 16 2.8 16 3.03333ZM5.55333 6H10.4467C10.9333 6 11.3333 5.6 11.3333 5.11333V2.22C11.3333 1.73333 10.9333 1.33333 10.4467 1.33333H5.55333C5.06667 1.33333 4.66667 1.73333 4.66667 2.22V5.11333C4.66667 5.6 5.06667 6 5.55333 6ZM14.6667 3.58667C14.6667 3.35333 14.5733 3.12667 14.4067 2.96L12.78 1.33333H12.6667V6.44667C12.6667 6.93333 12.2667 7.33333 11.78 7.33333H4.22C3.73333 7.33333 3.33333 6.93333 3.33333 6.44667V2.22C3.33333 1.73333 2.93333 1.33333 2.44667 1.33333H2.22C1.73333 1.33333 1.33333 1.73333 1.33333 2.22V13.78C1.33333 14.2667 1.73333 14.6667 2.22 14.6667H13.78C14.2667 14.6667 14.6667 14.2667 14.6667 13.78V3.58667Z"
                                  fill="#E8FFF3"
                                />
                              </svg>
                            )}
                            <p>{submitButtonTitle}</p>
                          </Button>
                        )}
                        {newButton &&
                          newButtonActions?.map(
                            (action: newButtonActionsProps) => (
                              <Button
                                type="button"
                                onClick={action.onClick}
                                className="newButton"
                              >
                                {/* @ts-ignore */}
                                {action.renderItem()}
                              </Button>
                            )
                          )}
                        {buttonPrint && (
                          <Button
                            className="printerButton"
                            onClick={actionPrintButton}
                          >
                            {t('components.pageMode.printOut')}
                          </Button>
                        )}
                        <Button onClick={handleClose} className="closeModal">
                          {t('components.pageMode.close')}
                        </Button>
                      </div>
                    </>
                  </ModalContentContainer>
                </ClickAwayListener>
              )}
            </ModalContainerDiv>
          </Fade>
        </>
      )}
    </>
  );
};

export default PageMode;
