import React from 'react';
import ReactDOM from 'react-dom';
import './i18n/index';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';

const isProd = process.env.REACT_APP_ENVIRONMENTTYPE === 'produção';

if (isProd) {
  Sentry.init({
    dsn:
      process.env.REACT_APP_SENTRY_URL ||
      'https://c47c634c9d994e71b85670c2c549b25f@o4504696494358528.ingest.sentry.io/4504698753318912',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
