import React, { lazy } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import FollowProducts from 'features/FollowProducts';
import Route from './route';

import Error400 from '../pages/Errors/400';
import Error401 from '../pages/Errors/401';

import Token from '../pages';

const Approved = lazy(() => import('features/Approved'));
const Receivement = lazy(() => import('features/Receivement'));
const CreateSeller = lazy(() => import('features/CreateSeller'));
const CreatePhrase = lazy(() => import('features/CreatePhrase'));
const ApprovedDetail = lazy(() => import('features/ApprovedDetail'));
const CreateConditions = lazy(() => import('features/CreateConditions'));
const DetailReceivement = lazy(() => import('features/DetailReceivement'));
const PublicationFollow = lazy(() => import('features/FollowPublication'));
const CreatePublication = lazy(() => import('features/CreatePublication'));
const ReportStock = lazy(() => import('features/ReportStock'));

const routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/auth" exact component={Token} />
        <Route
          path="/Publications/"
          exact
          component={PublicationFollow}
          isPrivate
          isInside
        />
        <Route
          path="/approvel/"
          exact
          component={Approved}
          isPrivate
          isInside
        />
        <Route
          path="/follow/"
          exact
          component={Receivement}
          isPrivate
          isInside
        />
        <Route
          path="/sellers/"
          exact
          component={CreateSeller}
          isPrivate
          isInside
        />
        <Route
          path="/phrases/"
          exact
          component={CreatePhrase}
          isPrivate
          isInside
        />
        <Route
          path="/conditions/"
          exact
          component={CreateConditions}
          isPrivate
          isInside
        />
        <Route
          path="/receivement/:receivementId"
          exact
          component={DetailReceivement}
          isPrivate
          isInside
        />
        <Route
          path="/approvedDetail/:receiptId"
          exact
          component={ApprovedDetail}
          isPrivate
          isInside
        />
        <Route
          path="/createPublication/"
          exact
          component={CreatePublication}
          isPrivate
          isInside
        />
        <Route
          path="/followProducts/"
          exact
          component={FollowProducts}
          isPrivate
          isInside
        />
        <Route
          path="/reportStock/"
          exact
          component={ReportStock}
          isPrivate
          isInside
        />
        <Route path="/401" component={Error401} />
        <Route path="*" component={Error400} />
      </Switch>
    </BrowserRouter>
  );
};

export default routes;
