const transformStatus = () => {
  const formatStatusProduct = (status: string) => {
    switch (status) {
      case 'DIVERGENT':
        return 'Divergente';
      case 'PENDING':
        return 'Pendente';
      case 'FINISHED':
        return 'Recebido';
      case 'IN_PROGRESS':
        return 'Em Andamento';
      case 'APPROVED':
        return 'Aprovado';
      case 'PUBLISHED':
        return 'Recebido';
      case 'DRAFT':
        return 'Aguardando Integração';
      case 'NO_PHOTO':
        return 'Sem Foto';
      case 'OK':
        return 'OK';
      case 'DEVOLVED':
        return 'Devolvido';
      case 'DISAPROVED':
        return 'Reprovado';
      case 'REJECTED':
        return 'Rejeitado';
      case 'ADJUSTMENT':
        return 'Ajuste';
      case 'AWAITING_INTEGRATION':
        return 'Aguardando Integração';
      case 'INTEGRATION':
        return 'Integrado';
      case 'WITHDRAWN':
        return 'Retirado';
      case 'CANCELED':
        return 'Cancelado';
      case 'PAID_OUT':
        return 'Pago';
      case 'DELIVERED':
        return 'Entregue';
      default:
        return 'Aprovado';
    }
  };

  const formatStatusReceipt = (status: string) => {
    switch (status) {
      case 'DIVERGENT':
        return 'Divergente';
      case 'PENDING':
        return 'Pendente';
      case 'FINISHED':
        return 'Recebido';
      case 'IN_PROGRESS':
        return 'Em Andamento';
      case 'CANCELED':
        return 'Cancelado';
      case 'PLANNED':
        return 'Planejado';
      case 'FINISHED_DIVERGENT':
        return 'Divergente';
      case 'PUBLISHED':
        return 'Publicado';
      case 'WITHDRAWN':
        return 'Retirado';
      case 'PAID_OUT':
        return 'Pago';
      default:
        return '';
    }
  };

  const editStatus = (
    isAttachments: boolean,
    etiquette: string,
    status: string
  ) => {
    if (
      status === 'FINISHED' ||
      status === 'DEVOLVED' ||
      status === 'PUBLISHED' ||
      status === 'APPROVED'
    ) {
      return formatStatusProduct(status);
    }
    if (isAttachments && etiquette !== null) {
      return 'OK';
    }
    if (!isAttachments && (etiquette === null || etiquette === '')) {
      return 'Pendente';
    }
    if (!isAttachments && etiquette !== null) {
      return 'Sem Foto';
    }
    return 'Devolvido';
  };

  return { formatStatusProduct, formatStatusReceipt, editStatus };
};

export { transformStatus };
