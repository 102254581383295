import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  .topTitle {
    color: ${props => (props.theme.title === 'light' ? '#6C7293' : '#FFFFFF')};;
  }

  .newProductButton {
    background-color: ${props =>
      props.theme.title === 'light' ? '#E8FFF3' : '#1C3238'};

    p {
      color: ${props =>
        props.theme.title === 'light' ? '#46be7d' : '#04aa77'} !important;
    }

    path {
      fill: ${props => (props.theme.title === 'light' ? '#46be7d' : '#04AA77')};
    }

    &:hover {
      background-color: #46be7d;
      transition: 0.5s;
      box-shadow: none;
      p {
        color: #e8fff3 !important;
      }
      path {
        fill: #e8fff3;
      }
    }
  }

  .upload {
    background-color: ${props =>
      props.theme.title === 'light' ? '#ecf8ff' : '#212E48'};

    p {
      margin-left: 8px;
      color: #187de4;
    }

    path {
      fill: #187de4;
    }

    &:hover {
      background-color: #009ef6;
      transition: 0.5s;
      box-shadow: none;
      p {
        color: #e8fff3 !important;
      }
      path {
        fill: #e8fff3;
      }
    }
  }

  .containerButtons {
    margin-bottom: 10px;
    display: flex;
    justify-content: end;

    .backButton {
      background: ${props =>
        props.theme.title === 'light' ? `#eff2f5` : '#474761'};

      p {
        padding: 0 8px;
        color: ${props =>
          props.theme.title === 'light' ? '#2b2b40' : '#eff2f5'};

          svg {
            margin-right: 7px !important;
          }

        path {
          margin-right: 7px;
          fill: ${props =>
            props.theme.title === 'light' ? '#474761' : '#eff2f5'} !important;
        }


        &:hover {
          transition: 0.5s;
          opacity: 0.9;
        }

      }


      img {
        padding-right: 7px;
      }
    }
  }

  .cancel {
    background-color: ${props =>
      props.theme.title === 'light' ? '#FFF5F8' : '#3A2434'};

    p {
      padding: 0 8px;
      color: #D9214E !important;

      path {
        fill: #D9214E !important;
      }

      svg {
        margin-right: 10px !important;
      }
    }

    &:hover {
      background-color: #D9214E;
      transition: 0.5s;
      box-shadow: none;

      p {
        color: #e8fff3 !important;

        path {
          fill: #e8fff3 !important;
        }
      }
    }
  }

  .planning {
    background-color: ${props =>
      props.theme.title === 'light' ? '#ecf8ff' : '#212E48'};

    p {
      padding: 0 8px;
      color: #187de4 !important;

      path {
        fill: #187de4;
      }

      svg {
        margin-right: 10px !important;
      }
    }

    &:hover {
      background-color: #009ef6;
      transition: 0.5s;
      box-shadow: none;

      p {
        color: #e8fff3 !important;
      }

      path {
        fill: #e8fff3;
      }
    }
  }

  button {
    height: 42px !important;
    margin-left: 13px;
    border: none;

    p {
      display: flex;
      font-weight: 600;
      font-style: normal;
      align-items: center;
      font-family: 'Poppins';
      text-transform: none;
      font-size: 12px !important;
    }
  }

}


  button {
    height: 42px !important;
    margin-left: 13px;
    border: none;

    p {
      display: flex;
      font-weight: 600;
      font-style: normal;
      align-items: center;
      font-family: 'Poppins';
      text-transform: none;
      font-size: 12px !important;
    }
  }
`;

export const CustomizedImgCellContainer = styled.div`
  max-width: 100%;
  img {
    max-width: 100%;
    display: block;
    object-fit: cover;
  }
`;

export const UserInputsContainer = styled.div`
  display: block;
  align-items: center !important;
  justify-content: flex-start;
  padding: 10px 10px 50px;
  font-size: 12px;

  .inputsArea {
    display: flex;
    align-items: center !important;
    justify-content: flex-start;
    max-width: 100%;
    padding: 5px 10px;
    font-size: 12px;
  }

  .phrasesArea {
    display: flex;
    font-size: 12px;
    box-sizing: border-box;
  }
`;

export const AddUserContainer = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  padding-top: 32px;
  margin-right: 20px;
  width: 80%;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: none;

  color: ${props =>
    props.theme.title === 'light' ? '#555555' : '#ECF8FFee'} !important;

  .containerItem {
    display: flex;
    justify-content: space-between !important;
  }

  .titleItem {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  .listItem {
    height: 52px;
    font-size: 12px;
    line-height: 15px;
    padding: 10px 15px;
  }

  .row-actions-button-detail {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    transition: 0.15s ease;

    .action {
      position: relative;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      transition: 0.15s ease;

      .hover-item {
        visibility: hidden;
        position: absolute;
        bottom: 40px;
        background: ${props =>
          props.theme.title === 'light' ? '#EEF0F8' : '#191924'};
        padding: 8px 16px;
        border-radius: 4px;
        box-shadow: 1px 5px 14px 0px rgba(0, 0, 0, 0.1);
        opacity: 0;
        transform: translateX(-50%) translateY(-5px);
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        color: ${props =>
          props.theme.title === 'light' ? '#6C7293' : '#FFFFFF'};
        left: 50%;
        transform: translateX(-50%);
        transition: 0.15s ease;
      }

      &:hover {
        background: ${props =>
          props.theme.title === 'light' ? '#f2f2f2' : '#222'};

        .hover-item {
          opacity: 1;
          visibility: visible;
          transform: translateX(-50%) translateY(0);
        }
      }

      & + .action {
        margin-left: 10px;
      }

      img {
        max-width: 50%;
      }
    }
  }

  .hover-item {
    visibility: hidden;
    position: absolute;
    opacity: 0;
    bottom: 40px;
    background: #000;
    padding: 8px 16px;
    border-radius: 4px;
    box-shadow: 1px 5px 14px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 1px 5px 14px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 1px 5px 14px 0px rgba(0, 0, 0, 0.1);
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #1e1e2d;
    left: 50%;
    transform: translateX(-50%) translateY(-5px);
    z-index: 10;
    transition: 0.3s;
  }
`;

export const InputLabel = styled.label`
  cursor: pointer;
  font-size: 12px;
  color: ${props => (props.theme.title === 'light' ? '#464e5f' : '#b5b5c3')};
  font-weight: 500;
  user-select: none;
`;

export const AddButton = styled.button`
  font-weight: 500;
  display: inline-flex;
  align-items: center !important;
  gap: 5px;
  border-radius: 6px;
  transition: 0.15s;
  font-size: clamp(12px, 14px, 4vmin);
  padding: 8px 1rem;
  margin-left: 10px;
  margin-top: 20px;
  align-self: bottom !important;

  background-color: ${props =>
    props.theme.title === 'light' ? '#ECF8FF' : '#212E48'};
  color: #0095e8;

  &:hover {
    color: #fff;
    background-color: #0095e8;
  }
`;
