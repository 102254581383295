import styled from 'styled-components';

interface ContainerProps {
  containerClosed?: boolean;
  isSelected?: boolean;
  opened: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  cursor: pointer;
  .title {
    width: 100%;
    height: 44px;
    background-color: inherit;
    .container {
      transition: 0.5s;
      background: ${props =>
        props.isSelected ? `#212E48` : `${props.theme.colors.sidebar}`};
      border-radius: 5px;

      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 0.5rem;
      .left {
        display: flex;
        padding-left: 0.5rem;
        align-items: center;
        justify-content: ${props =>
          props.containerClosed ? 'center' : 'initial'};
        width: 100%;
        .icon-closed {
          svg {
            color: #ffffff;
            width: 25px;
            max-width: 25px;
            max-height: 25px;
            path {
              fill: ${props => (props.isSelected ? '#187DE4' : '#fcfcff')};
            }
          }
          animation-delay: 0.5s;
          transition: all 0.5s;
          animation: ${props =>
            props.containerClosed
              ? 'opacityOut 0.0s'
              : 'opacity 0.0s'} !important;
          width: 24px;
          height: 24px;
          filter: ${props =>
            props.isSelected ? 'grayscale(0%)' : 'grayscale(100%)'};
          img {
            max-width: 100%;
          }
        }
        .icon {
          svg {
            color: #ffffff;
            max-width: 16px;
            path {
              fill: ${props => (props.isSelected ? '#187DE4' : '#fcfcff')};
            }
          }
          animation-delay: 0.5s;
          animation: ${props =>
            props.containerClosed
              ? 'opacityOut 0.3s ease-in-out both'
              : 'opacity 0.0s'} !important;
          width: 24px;
          height: 24px;
          img {
            max-width: 100%;
          }
        }

        &.selectedIcon {
          background: ${props => props.theme.colors.sidebarSelected};
          filter: hue-rotate(100%);
        }
        .text {
          margin-left: 8px;
          width: 100%;
          text-align: left;
          animation-delay: 0.5s;
          animation: ${props =>
            props.containerClosed
              ? '0.3s ease-in-out alternate both opacity'
              : 'opacityOut 0.0s'} !important;
          p {
            font-size: 13px;
            color: ${props => (props.isSelected ? '#187DE4' : '#fcfcff')};
            /* &:hover {
              color: #fff;
            } */
          }
        }

        @keyframes opacity {
          0% {
            opacity: 1;
            display: initial;
          }

          60% {
            opacity: 0;
          }

          100% {
            opacity: 0;
            display: none;
            visibility: hidden;
            z-index: -5;
            position: fixed;
          }
        }

        @keyframes opacityOut {
          0% {
            opacity: 0;
            display: none;
            z-index: -5;
            position: fixed;
            visibility: hidden;
          }

          60% {
            opacity: 0;
          }

          100% {
            opacity: 1;
            display: initial;
          }
        }

        @keyframes iconOpacity {
          0% {
            opacity: 1;
            display: initial;
          }

          60% {
            opacity: 0;
          }

          100% {
            opacity: 0;
            display: none;
            z-index: -5;
            position: fixed;
          }
        }

        @keyframes iconOpacityOut {
          0% {
            opacity: 0;
            display: none;
            z-index: -5;
            position: fixed;
          }

          60% {
            opacity: 0;
          }

          100% {
            opacity: 1;
            display: initial;
          }
        }
      }
      .arrow {
        display: ${props =>
          props.containerClosed ? 'none' : 'initial'} !important;
        align-items: center;
        margin-bottom: ${props => (props.opened ? '5px' : '1px')};
        svg {
          path {
            fill: ${props => (props.isSelected ? '#187DE4' : '#fcfcff')};
          }
        }
        transition: transform 0.3s;
        transform: ${props =>
          props.opened ? 'rotate(0deg)' : 'rotate(-90deg)'};
        opacity: 1;
      }
      &:hover {
        background-color: ${props =>
          props.opened || props.isSelected === true ? `` : `#151521`};
        transition: 0.1s !important;

        .left {
          .icon {
            filter: grayscale(0%);
          }
        }
        .arrow {
          opacity: 1;
        }
      }
    }
  }
  .sub-itens {
    margin-top: 4px;
    display: ${props =>
      props.opened && !props.containerClosed ? 'block' : 'none'};
    animation: ${props =>
      props.containerClosed ? 'opacity 0.2s ease-in-out both' : ''} !important;
    .container {
      padding-left: 10px;
      .item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* padding: 8px 0; */
        padding-left: 19px;
        border-left: 1px solid #5b5b5b;
        .link {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 0px 9px;
          border-radius: 4px;
          a {
            font-size: 12px;
            line-height: 25px;
            font-weight: 500;
            color: ${props => props.theme.colors.sidebarLink};
            width: 100%;
          }
          &.selected {
            background: ${props =>
              props.isSelected ? `#212E48` : `${props.theme.colors.sidebar}`};
            border-radius: 5px;
            &:hover {
              background: #212e48 !important;
            }
            a {
              color: #187de4;
            }
          }
          &:hover {
            background: #151521;
            transition: 0.1s !important;
          }
        }

        .arrow {
          display: flex;
          align-items: center;
        }
      }
    }
  }
  @media screen and (max-width: 910px) {
    .title {
      .container {
        .left {
          .text {
            display: block;
          }
        }
      }
    }
  }
`;
