import React, { createContext, useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import api from 'services/api';
import { setCookie, getCookie, removeAllCookies } from 'utils/cookies';

interface SignInCredentials {
  token_acc: string | null;
  cliente: number | string | null;
}

interface AuthState {
  access_token: string;
  user: ResponseData | null;
}
interface ResponseData {
  data: {
    accessToken: string;
    codigoUsuario: number;
    login: string;
    nome: string;
    email: string;
    ativo: boolean;
    alterarSenha: boolean;
    codigoPerfil: number;
    descricaoPerfil: string;
    lider: boolean;
  };
}

interface AuthContextData {
  user: ResponseData | null;
  token: string;
  signIn(credentials: SignInCredentials): Promise<ResponseData | undefined>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const location = useHistory();

  const [data, setData] = useState<AuthState>(() => {
    const access_token = getCookie('@pdamodules::token');
    const user = getCookie('@pdamodules::user');

    if (access_token && user) return { access_token, user } as AuthState;

    return {
      access_token: 'void',
      user: null,
    } as AuthState;
  });

  const signIn = useCallback(
    async ({ token_acc, cliente }: SignInCredentials) => {
      const cookieAge = 90000;

      try {
        const responseLogin: ResponseData = await api.get(
          `Autenticacao?refreshToken=${token_acc}`
        );

        if (responseLogin.data.accessToken) {
          const user: ResponseData = { data: responseLogin.data };
          const { accessToken, codigoUsuario, ativo, login } =
            responseLogin.data;

          api.defaults.headers.Authorization = `Bearer ${accessToken}`;

          setCookie('@pdamodules::refreshtoken', token_acc, 3600);
          setCookie('@pdamodules::token', accessToken, 3000);
          setCookie('@pdamodules::user', user, cookieAge);
          setCookie('@pdamodules::codigoCliente', cliente, cookieAge);
          setCookie('@pdamodules::id', codigoUsuario, cookieAge);
          setCookie('@pdamodules::active', ativo, cookieAge);
          setCookie('@pdamodules::login', login, cookieAge);

          setData({
            access_token: responseLogin.data.accessToken,
            user,
          });

          window.analytics.identify(user.data.codigoUsuario, {
            name: user.data.nome,
            email: user.data.email,
          });

          location.push('/wms');

          return user;
        }
        return undefined;
      } catch (err) {
        console.log(err);
        return undefined;
      }
    },
    [location]
  );

  const signOut = useCallback(() => {
    removeAllCookies();
    sessionStorage.clear();
    setData({} as AuthState);

    window.location.replace(
      `${
        process.env.REACT_APP_ENV === 'dev'
          ? process.env.REACT_APP_LOGOUT_DEV_URL
          : process.env.REACT_APP_LOGOUT_PROD_URL
      }`
    );
  }, []);

  return (
    <AuthContext.Provider
      value={{ token: data.access_token, user: data.user, signIn, signOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}

export { AuthContext, AuthProvider, useAuth };
