/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, ClickAwayListener, Popper, Fade } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'hooks/Auth';
import { AboutList, MenuLinkContainer } from '../styles';

interface IAboutMenuProps {
  anchor: HTMLButtonElement | null;
  isOpen: boolean;
  handleClose(): void;
}

interface IMenuLinkProps {
  path?: string;
  title: string;
  onClick?: () => void;
}

const MenuLink: React.FC<IMenuLinkProps> = ({ path, title, onClick }) => (
  <MenuLinkContainer
    href={path}
    rel="noopener noreferrer nofollow"
    target="_blank"
    onClick={onClick}
  >
    {title}
  </MenuLinkContainer>
);

const AboutMenu: React.FC<IAboutMenuProps> = ({
  anchor,
  handleClose,
  isOpen,
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const helpUrl = String(process.env.REACT_APP_HELP_PROD_URL) + pathname;
  const { signOut } = useAuth();

  const Logout = useCallback(() => {
    signOut();
  }, [signOut]);

  return (
    // @ts-ignore
    <Box className="popper-box">
      <Popper
        open={isOpen}
        anchorEl={anchor}
        className="popper"
        style={{ zIndex: 999999 }}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Fade in timeout={1000}>
            <AboutList>
              <div>
                <MenuLink
                  title={t('header.aboutMenu.support')}
                  path="mailto:suporte@pdasolucoes.com.br"
                />
              </div>
              <div>
                <MenuLink title={t('header.aboutMenu.help')} path={helpUrl} />
              </div>
              <div>
                <MenuLink
                  title={t('header.aboutMenu.about')}
                  path="http://pdaforms.com.br/a-pda/"
                />
              </div>
              <div>
                <MenuLink title="Sign Out" path="/" onClick={Logout} />
              </div>
            </AboutList>
          </Fade>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};

export default AboutMenu;
