export default {
  translations: {
    header: {
      aboutMenu: {
        support: 'Apoyo',
        help: 'Ayuda',
        about: 'Acerca',
      },
    },
    screens: {
      receivement: {
        follow_up: 'Acompañamiento',
        receivement: 'Recibo',
        requestType: 'Tipo de Solicitud',
        order: 'Pedir',
        invoice: 'Factura Fiscal',
        status: 'Estado',
        series: 'Serie',
        product: 'Producto',
        supplier: 'Proveedor',
        initialDate: 'Fecha Inicio',
        finalDate: 'Fecha Final',
        details: 'Detalles',
        generateConference: 'Generar Conferencia',
        endDivergence: 'Termina con la Divergencia',
        confirmEndDivergence:
          '¿Estás seguro de que quieres terminar con la divergencia?',
        orderFinisherEnd: 'Pedido completado con discrepancia',
        progress: 'Progresso',
        actions: 'Comportamiento',
        dateApproved: 'Fecha Apro',
        amountSku: 'Ctdd. SKU',
        amount: 'Ctdd',
        dateReceivement: 'Fecha Rec',
        dateEnd: 'Fin Rec',
        alertSeach:
          'Por favor, introduzca fechas con una diferencia máxima de 30 días o filtre por pedido y factura',
        successFilter: 'resultados encontrados',
        resultsNotFound: 'Resultados no encontrados',
        document: 'Documento',
        receiptDate: 'Fecha de recepción',
        endDate: 'Fecha final',
        enterProduct: 'Introduce el producto',
        enterBox: 'Entrar en la caja',
        enterPallet: 'Entrar en la paleta',
        dateChecked: 'Fecha comprobada',
        size: 'Tam.',
        quantity: 'Qtd. NF',
        divergent: 'Divergencia',
        dateReceipt: 'Fecha Rec.',
        successfullyReversed: 'revertido con éxito',
        reversedSuccessfully: 'Recibo revertido con éxito',
        isReverse: '¿Quieres revertir el recibo',
        finallyMsg: '¿Seguro que quieres terminar?',
        finallySuccess: '¡Pedido completado!',
        cancelMsg: '¿Estas seguro que quieres cancelar?',
        cancelSuccess: '¡Solicitud cancelada!',
        cancelOrder: 'Cancelar orden',
        successfullyDeleted: '¡Eliminado con éxito!',
        products: 'Productos',
      },
      status: {
        canceled: 'Cancelado',
        divergent: 'Divergente',
        inProgress: 'En proceso',
        finished: 'Finalizado',
        completedDivergent: 'Divergente Completado',
        integrated: 'Integrada OK',
        integratedDivergent: 'Integrada Divergente',
        invoiceDivergent: 'Factura Divergente',
        pending: 'Pendente',
        receipt: 'Recibido',
      },
      cardsFilters: {
        results: 'Resultados',
        finished: 'Finalizado',
        completedDivergent: 'Divergente Completado',
        inProgress: 'En Proceso',
        divergent: 'Divergente',
        pending: 'Pendiente',
        totalProgress: 'Progresso Total',
        create: 'Creado',
        planned: 'Planificado',
        progress: 'Progreso',
        resupply: 'Reabastecimiento',
        completedShipment: 'Envío Completado',
        finishedDivergence: 'Terminado C/ Divergencia',
        insufficientStock: 'Existencias Insuficientes',
        awaitingIntegration: 'En espera de Integración',
        checkedQuantity: 'Cantidad Marcada',
        totalAmount: 'Cantidad total',
        startDate: 'Fecha Inicio Separación',
        startTime: 'Separación Inicio Tiempo',
        endDate: 'Fecha Finalización del Envío',
        endTime: 'Hora Finalización Embarque',
        awaitingBilling: 'En Espera de Facturación',
        awaitingShipment: 'En Espera de Envío',
        awaitingConference: 'Conferencia en Espera',
        shipmentFinished: 'Envío Terminado',
        awaitingRelease: 'En Espera de Lanzamiento',
        divergentQuantity: 'Cantidad divergente',
        amountReceived: 'Cantidad recibida',
        published: 'Publicado',
        devoluition: 'Devolución',
        noPhoto: 'Sin Foto',
        devolved: 'Devuelto',
      },
      structureAdresses: {
        type: 'Tipo',
        description: 'Descripción',
        isActive: '¿Está activo?',
        noFound: 'No se encontró ninguna dirección',
        adreessesFound: 'direcciones encontradas',
        structureEditSuccesses: 'Estructura editada con éxito',
        successDeleteRecordes: 'Registro eliminado con éxito',
        notFoundRecordes: 'No se encontraron registros.',
        wasFound: 'Fue encontrado',
        records: 'registros',
        someError: 'Algo salió mal con la solicitud',
        structureCreate: 'Structure created successfully!',
        structure: 'Estructura',
        titleScreen: 'Crear estructura',
        newStrucutureBtn: 'Nueva Estructura',
        create: 'Crear',
        createStructureTitle: 'Crear estructura',
        edit: 'Para editar',
        successfullyDeleted: '¡Eliminado con éxito!',
        isDeleted: '¿Quieres borrar?',
        status: 'Estado',
        actions: 'Comportamiento',
        delete: 'Borrar',
        statusTrue: 'Activo',
        statusFalse: 'Inactivo',
        all: 'Todo',
      },
      address: {
        deposit: 'Depósito',
        type: 'Tipo',
        status: 'Estado',
        adresses: 'Dirección',
        toWalk: 'Andar',
        road: 'Camino',
        modules: 'Módulos',
        level: 'Nivel',
        position: 'Posición',
        resulpply: 'Reabastecimiento',
        capacity: 'Capacidad',
        resu: 'Reab.',
        actions: 'Comportamiento',
        delete: 'Borrar',
        edit: 'Para editar',
        editStatus: 'Editar Estado',
        newAddress: 'Nueva Dirección',
        storage: 'Almacenamiento',
        notFound: 'No hay registros de direcciones',
        foundResults: 'resultados encontrados',
        error: 'Error',
        branch: 'Subsidiaria',
        shippingCompany: 'Compañía de envios',
        branchsNotName: 'No hay sucursales con ese nombre.',
        editedSuccessfully: '¡Editado con éxito!',
        saveButtonTitle: 'Ahorrar',
        notLeave: '¡No deje el campo de estado vacío!',
        updateStatus: '¡Estado actualizado con éxito!',
        deleteSuccess: 'Eliminado con éxito',
        isDelete: '¿Está seguro de que desea eliminar la dirección',
        deleteAddress: 'Dirección eliminada',
        createAddressTitle: 'Crear Dirección',
        createAddressSuccess: '¡Creado con éxito!',
        addressExist: '¡Dirección existente!',
        importFile: 'Importar Archivo',
        importSuccess: '¡Archivo importado con éxito!',
        uploadFile: 'Subir archivo',
        choiceFile: 'Elija el archivo para upload.',
      },
      logAddress: {
        product: 'Producto',
        address: 'Dirección',
        initialDate: 'Fecha Inicio',
        finalDate: 'Fecha Final',
        box: 'Caja',
        pallet: 'Paleta',
        type: 'Tipo',
        noRecordes: '¡No se encontraron registros!',
        logStorage: 'Log Almacenamiento',
        quantityPalete: 'Cantidad Palets',
        quantityBox: 'Cantidad Caja',
        quantity: 'Cantidad',
        manufacturer: 'Fabricante',
        descriptionProduct: 'Descripción del producto',
        dateStorage: 'Fecha de Almacenamiento',
        alertSearch:
          'Ingrese fechas con una diferencia máxima de 30 días o filtre por producto o dirección',
      },
      orderFollowUp: {
        title: 'Orden',
        followUp: 'Acompañamiento',
        orderType: 'Tipo Orden',
        client: 'Cliente',
        period: 'Período',
        clientBranch: 'Client/Subsidiaria',
        receive: 'Recibió',
        planning: 'Planificación',
        finished: 'Finished',
        endShipment: 'Finalizar Envío',
        resultsNotFound: 'Resultados no encontrados.',
        recordsFound: 'Fue encontrado',
        clientAndBranch: 'Cliente/Subsidiaria',
        quantity: 'Cantidad',
        received: 'Recibió',
        check: 'Controlar',
        color: 'Color',
        orderProgress: 'Progreso del Pedido',
        branchsNotName: 'No hay sucursales con ese nombre.',
        printing: 'Impresión',
        alertSearch:
          'Ingrese fechas con una diferencia máxima de 30 días o filtre por ordem',
      },
      reverseOrder: {
        errorTheRequest: 'Error: La solicitud',
        thisStatusCreate: 'esto ya es como el estado creado!',
        orderStatus: 'Estado del orden',
        changedSuccessfully: 'changed successfully!',
        noRecordesFound: 'No se encontraron registros.',
        reverse: 'Contrarrestar',
        wave: 'Ola',
        editStatusTitle: 'Editar Estado - Orden:',
        dateOrder: 'Fecha Orden',
        quantityPieces: 'Ctd de Piezas',
        newWave: 'Nueva Ola',
        alertSearch:
          '¡Ingrese fechas con una diferencia máxima de 30 días o filtre por orden u ola!',
      },
      createWave: {
        included: 'incluido',
        onWave: 'en la ola',
        notIncluded: 'no incluido',
        branchsNotName: 'No hay sucursales con ese nombre.',
        picking: 'Cosecha',
        createWave: 'Crear ola',
        taxUnit: 'UT',
        customerBranch: 'Cliente/Sucursal',
        quantityPieces: 'Qtd Piezas',
        newWave: 'Nueva Ola',
        selectedOrder: 'pedido(s) seleccionado(s)',
        priority: 'Prioridad',
        splitVolume: 'Volumen Dividido?',
        alertSearch:
          'Ingrese fechas con una diferencia máxima de 30 días o filtre por pedido.',
      },
      followPicking: {
        branchsNotName: 'No hay sucursales con ese nombre.',
        picking: 'Cosecha',
        editUser: 'Editar Usuario',
        changeUser: 'Cambiar Usuario',
        editUserSuccess: 'Usuario editado con éxito',
        followPicking: 'Seguimiento de la Conferencia',
        separationFollowUp: 'Seguimiento de separaciones',
        priority: 'Prioridad',
        customerBranch: 'Cliente/Sucursal',
        quantityPieces: 'Qtd. Piezas',
        quantityPicking: 'Qtd. Cosecha',
        quantityChecked: 'Qtd. Tomprobado',
        dateOrder: 'Fecha Orden',
        dateFinal: 'Fecha Final',
        document: 'Documento',
        startDate: 'Fecha de inicio:',
        finalDate: 'Fecha final:',
        alertSearch:
          'Ingrese fechas con una diferencia máxima de 30 días o filtre por ola y orden.',
      },
      pickingConference: {
        product: 'Producto:',
        isResult: 'comprobado con éxito. Orden:',
        picking: 'Cosecha',
        conference: 'Conferencia',
        conferenceDetails: 'Detalle de la Conferencia',
        finalize: 'Finalizar!',
        conferenceFinished: '¡Conferencia finalizada!',
        boxAlreadyAddressed: 'Caja ya direccionada!',
        boxNotExist: '¡La caja no existe!',
        boxNotBelong: '¡La caja no pertenece al pedido!',
        invalidBarcode: 'Código de barras no válido',
        validatedSucces: '¡Ha sido validado con éxito!',
        quantityExceeded: '¡Cantidad excedida!',
        insertBox: '¡Por favor, inserte una caja para recoger!',
        endConference: '¿Está seguro de que desea finalizar la conferencia?',
        barCode: 'Cód. barra',
        code: 'Código de Barras',
        box: 'Caja',
        check: 'Controlar',
        conferred: 'Conferido',
        qty: 'Qtd.',
        boxType: 'Tipo de caja',
      },
      transferenceFollowUp: {
        transfer: 'Transferir',
        origin: 'Origen',
        destiny: 'Destino',
        unit: 'Unidad',
        type: 'Tipo',
        transferDate: 'Fecha de Transferencia',
        alertSearch:
          'Ingrese fechas con una diferencia máxima de 30 días o filtre por unidad.',
      },
      expeditionFollowUp: {
        expedition: 'Expedición',
        query: 'Consulta',
        state: 'Estado',
        dispatchDate: 'Fecha de Envio',
        customerBranch: 'Cliente/Sucursal',
        dispatchUser: 'Usuario Despacho',
        codeOrder: 'Cód. Orden',
        codeExpedition: 'Cód. Expedición',
        validProduct: 'Producto Valido',
        startDate: 'Fecha de Inicio del Envío',
        endDate: 'Fecha de Finalización del Envío',
        branchsNotName: 'No hay sucursales con ese nombre.',
        alertSearch:
          'Ingrese fechas con una diferencia máxima de 30 días o filtre por pedido.',
      },
      boarding: {
        boarding: 'Embarque',
        packingList: 'Lista Embalaje',
        customerBranch: 'Cliente/Sucursal',
        invoiceQuantity: 'Qtd. NF',
        volumeQuantity: 'Qtde. Volumen',
        exportButton: 'Exportar listas de embalaje en PDF',
        requestCode: 'Código orden',
        orderType: 'Tipo de orden',
        packingDetails: 'Detalles Empaque',
        recordFound: 'registros encontrados',
        alertSearch:
          'Ingrese fechas con una diferencia máxima de 30 días o filtre por lista de empaque.',
      },
      manualOrder: {
        manualOrder: 'Registro manual',
        fileUpload: 'Subir archivo',
        register: 'Registro',
        requireFilds: 'Rellene los campos obligatorios',
        receipt: 'Recibo:',
        successfullyCreated: 'creado con exito!',
        order: 'Order:',
        created: 'creado!',
        operation: 'Operación',
        chooseOperation: 'Elige la operación',
        prohibited: 'Prohibido',
        exit: 'Salida',
        chooseType: 'Elige un tipo',
        enterInvoice: 'Introduce la factura',
        series: 'Serie',
        date: 'Date',
        branchCustomer: 'Código cliente ERP/Sucursal',
        carrierCode: 'Código de transportista',
        supplier: 'Proveedor',
        chooseSupplier: 'Elegir proveedor',
        items: 'Elementos',
        deletedItems: 'Objetos eliminados',
        deletedItem: 'Elemento eliminado',
        withSuccess: 'con éxito',
        ordem: 'Orden',
        delete: 'Borrar',
        validData: 'Introduce datos válidos',
        addedSuccessfully: 'gregado exitosamente!',
        nameAndCode: 'No hay productos con este nombre/código',
        chooseProduct: 'Elige un producto',
        enterAmount: 'Introduce una cantidad',
        toAdd: 'Para agregar',
        chooseUpload: 'Elija el archivo para cargar.',
        liftFile: 'Subir archivo',
        chooseRegistration: 'Elija el formulario de registro',
      },
      parameters: {
        parameters: 'Parámetro',
        administration: 'Administración',
        successfullyEdited: '¡Estructura editada con éxito!',
        enterParemeter: 'Por favor ingrese el parámetro',
        parametersDescription: 'Por favor ingrese la descripción del parámetro',
        value: 'Valor',
      },
      registerProduct: {
        searchProduct: '¡Busca un producto!',
        thereProducts: 'No hay productos',
        productsFound: 'productos encontrados',
        register: 'Registro',
        productCode: 'Código del producto',
        marked: 'Marcado',
        unchecked: 'Desenfrenado',
        productEdited: 'Producto editado con éxito',
        active: 'Activo',
        level: 'Nivel',
        height: 'Altura',
        width: 'Ancho',
        length: 'Comprimento',
        weight: 'Peso',
        component: 'Componente',
        brandCode: 'Código de marca',
        receipt: 'Recibo',
        receiptInDays: 'Recibo (en días)',
        exit: 'Salida',
        parameters: 'Parámetros',
        marketingStructure: 'Estructura de mercadeo',
        departureInDays: 'Salida (en días)',
        batchControl: 'Control Lotes',
        controlsValidity: 'Validez de los Controles',
        controlsReceipt: 'Recepción de lotes Controles',
        receiptValidity: 'Vigencia del recibo Controles',
        constrolStorage: 'Lote de almacenamiento Controles',
        storageValidity: 'Validez de almacenamiento Controles',
        constrolSeparation: 'Separación de lotes Control',
        separationValidity: 'Vigencia de Separación Controles',
        checkControl: 'Compruebe el control Lotes',
        validityCheck: 'Comprobación de validez Control',
        notFieldsEmpty: 'No dejes campos vacíos',
        colorOrDescription: 'Color o Descripción',
        colorDesc: 'Color/Desc',
        delete: 'Borrar',
      },
      produtivityConference: {
        produtivity: 'Productividad',
        conference: 'Conferencia',
        name: 'Nombre',
        summaryTable: 'Tabla de Resumen',
        analyticalTable: 'Mesa Analítica',
        totalHours: 'Horas totales',
        ordersTime: 'Tiempo de pedidos',
        amountOrder: 'Qtd. Pedido',
        addressesTime: 'Qtd. Direcciones Hora',
        amountAddresses: 'Qtd. Direcciones',
        productTime: 'Tiempo del producto',
        amountProduct: 'Qtd. Producto',
        researchDate: 'Fecha de Investigación',
        fisrtMessage: 'resultado(s) se encontraron en el análisis y',
        lastMessage: 'resultado(s) encontrado(s) en sintético',
        alertSearch:
          'Ingrese fechas con una diferencia máxima de 30 días o filtre por nombre.',
        avgHours: 'H. Promedio Ini.',
        endAvgHours: 'H. Promedio Fin',
        totalHourOrders: 'Pedidos Horas T.',
        quantityHours: 'Qtd. Pedidos Horas T.',
        quantityAddress: 'Qtd. Dir. Hora T.',
        totalAddress: 'Qtd. Direcciones T.',
        productHours: 'Producto Hora T.',
        quantityHoursTotal: 'Qtde. Producto T.',
      },
      produtivityStorage: {
        notFieldEmpty: 'No dejes este campo vacío',
        choiceDate: '¡Elige una fecha de inicio y finalización!',
      },
      reportsStock: {
        boarding: 'Embarcar',
        brand: 'Marca',
        address: 'Dirección',
        descriptionProduct: 'Descripción del Producto',
        codeRequest: 'Código de Solicitud',
        reserved: 'Reservado',
        available: 'Disponible',
        saleUnitValue: 'Venta de unidades VLR',
        saleTotalValue: 'Venta total de VLR',
        saleMarketUnitValue: 'Mercado Unitario VLR',
        saleMarketValue: 'Mercado total de VLR',
        completionDateReceipt: 'Recibo Fecha Finalización',
      },
      invoiceDivergent: {
        reports: 'Informes',
        orderDivergent: 'Orden Divergente',
        series: 'Serie',
        date: 'Fecha',
        invoiceQuantity: 'Qtd. Nota Fiscal',
        orderQuantity: 'Qtd. Orden',
        alertSearch:
          'Ingrese fechas con una diferencia máxima de 30 días o filtre por pedido.',
      },
      ressupply: {
        ressupply: 'Reabastecimiento',
        origin: 'Orige',
        destiny: 'Destino',
        priority: 'Prioridad',
        reports: 'Informes',
      },
      insufficient: {
        insufficient: 'Insufficient',
        reports: 'Informes',
        priority: 'Prioridad',
      },
      separation: {
        reports: 'Informes',
        separation: 'Separación',
        quantityPickink: 'Qtd. Cosecha',
        quantityOrder: 'Qtd. Orden',
        series: 'Serie',
        nameClient: 'Nombre Cliente',
        codeClient: 'Cod. Cliente',
        codShippingCompany: 'Cod. Compañía de envios',
        descriptionAddress: 'Desc. Dirección',
        billingIssue: 'Problema de facturación',
        billingDate: 'Fecha de facturación',
        homeSeparation: 'Separación de viviendas',
        endSeparation: 'Separación final',
      },
      conference: {
        reports: 'Informes',
        conference: 'Conferencia',
        quantityConference: 'Qtd. Conferencia',
        quantityOrder: 'Qtd. Orden',
        series: 'Serie',
        nameClient: 'Nombre Cliente',
        integrationDate: 'Fecha de Integración',
        homeConference: 'Inicio Conferencia',
        endConference: 'Fin de la conferencia',
        finalize: 'Finalizar',
        station: 'Station',
        selectStation: 'Seleccione la Estación',
        errorMessage:
          '¡Ups! Asegúrese de que todos los pedidos y estaciones estén llenos',
      },
      reportReceipt: {
        receipt: 'Recibo',
        reports: 'Informes',
        integrationDate: 'Fecha de Integración',
        series: 'Serie',
        alertSearch:
          'Introduzca fechas con una diferencia máxima de 30 días o filtre por pedido o factura.',
      },
      dashboard: {
        awaitingBilling: 'En Espera de Facturación',
        awaitingIntegration: 'En espera de Integración',
        conference: 'Conferencia',
        ddressesUsed: 'Direcciones utilizadas',
        stock: 'Existencias',
        expedition: 'Expedition',
        insufficient: 'Insuficiente',
        requests: 'Peticiones',
        pickingProgress: 'Selección divergente',
        divergentPicking: 'Divergent Picking',
        plannedPicking: 'Recolección Planificada',
        resupply: 'Reabastecimiento',
      },
      invoiceReceivement: {
        invoicePlaceholder: 'Ingrese el número de Factura',
        sellerPlaceholder: 'Seleccione el vendedor',
        receivementPlaceholder: 'Ingrese el número de Recibo',
        receipt: 'Recibo',
        abbreviatedInvoive: 'FAC',
        series: 'Serie',
        receivingDate: 'Fecha de Rec.',
        volume: 'Volúmenes',
        volReceipt: 'Vol. Rec.',
        vulueInvoive: 'Valor FAC',
        amountReceived: 'Cantidad Recibida',
        dateReceived: 'Fecha de recepción',
        totalValue: 'Valor total',
        enterSerie: 'Introduzca la serie FAC',
        createReceipt: 'Crear Recibo',
        deleteMessage: '¿Está seguro de que desea eliminar',
        successfullyDeleteMessage: '¡FAC eliminado con éxito!',
        titleTable: 'Lista de recibos',
        enterInvoice: 'Introduce una factura',
        enterQuantityVolumes: 'Introduzca la Cant volúmenes',
        enterTotalValue: 'Introduce el importe total',
        receiptRegistration: 'Registro de recibos',
        errorForm: 'Rellena todos los campos del formulario!',
        createError: '¡Cree al menos un recibo!',
        createSucces: '¡Recibo creado con éxito!',
        regiterTitle: 'Registro manual de recibos',
        newReceipt: 'Nuevo recibo',
        finalize: 'Finalizar',
        planning: 'Planificar ',
        cancelOrderMsg: 'Cancelar el pedido',
        cancelSuccessfully: 'cancelado con éxito!',
        finalizeOrderMsg: 'Finalizar pedido',
        finalizeSuccessfully: 'completado satisfactoriamente!',
        planOrderMsg: 'Orden del plan',
        successfullyPlannedMsg: 'planeado con éxito!',
        newProduct: 'Nuevo Producto',
        items: 'Elementos',
        etiquetteLabel: 'Etiqueta PDA',
        enterEtiquette: 'Escriba la etiqueta',
        category: 'Categoría',
        enterCategory: 'Seleccione la categoría',
        subcategory: 'Subcategoría',
        enterSubcategory: 'Seleccione subcategoría',
        photograph: 'Fotografía',
        maturity: 'Madurez',
        disassembled: 'Desmontado',
        incomplete: 'Incompleto',
        standardPhrase: 'Frase estándar',
        comments: 'Comentarios',
        description: 'Descripción',
        deleteEtiquetteMsg: 'Eliminar etiqueta',
        etiquette: 'Etiqueta',
        successfullyDeletedMsg: 'eliminado con éxito!',
        productValue: 'Valor del producto',
        validity: 'Validez',
        invoiceProduct: 'FAC de Producto',
        productRegistration: 'Registración del Producto',
        condition: 'Condición',
        newSeller: 'Nuevo Vendedor',
        editSeller: 'Editar Vendedor',
        deleteSeller: 'Eliminar Seller',
        createSeller: '¡El vendedor se creó correctamente!',
        deleteFailed: '¡Vendedor no encontrado!',
        editSellersuccessfully: '¡El vendedor editó con éxito!',
        startDate: 'F. de inclusión',
        finalDate: 'F. de terminación',
        selectUser: 'Seleccionar usuario',
        mainPhoto: '¿Foto principal?',
        addUser: 'Agregar Usuario',
        userList: 'Lista de Usuarios',
        addUsersSuccess: 'Usuarios añadidos correctamente!',
        type: 'Tipo',
        selectType: 'Seleccione o Tipo',
        invoiceSeller: 'FAC Seller',
        warningImports:
          '¡Ups, por favor verifica los campos en el archivo que deseas importar!',
        warningFinished:
          '¡Ups! Hay productos en este recibo con etiquetas que no tienen una foto asociada!',
        unitMeasurement: 'Unidad de Medida',
        invoiceItem: 'FAC Item',
        enterDescription: 'Ingrese la descripción',
        warningUploads: '¿Este pedido tiene',
        warningUploadsPt2: 'productos, desea sobrescribirlos?',
        enterWidth: 'Ingrese el ancho',
        enterHeight: 'Ingrese la altura',
        enterDepth: 'Ingrese la profundidad',
        enterVoltage: 'Ingrese el voltaje',
        isEven: '¿Es par?',
        enterHeritage: 'Ingrese el patrimonio',
        enterWeigtht: 'Ingrese el peso',
        heritage: 'Patrimonio',
        voltage: 'Voltaje',
        depth: 'Profundidad',
        heigth: 'Altura',
        width: 'Ancho',
        weigth: 'Peso',
        return: 'Devolver',
        warningReturn: '¿Devolver producto',
        successfullyReturned: '¡Producto devuelto con éxito!',
        warningDivergente: '¿Existen elementos pendientes, deseas finalizar?',
        noProduct: '¡Este pedido no contiene productos!',
        statusReceipt: 'Status Aprobación',
        volumeRecevement: 'Volumen Recibido',
        etiquetteAnotherReceipt: '¡Etiqueta ya en otro recibo!',
        existsEtiquette: '¡Verifique si la etiqueta existe!',
        editProductSuccess: '¡Producto editado con éxito!',
        createProductSuccess: 'Produto creado com sucesso!',
        selectOrigin: 'Seleccionar origen',
        unitSaleValue: 'Valor de venta unitario',
        totalSaleAmount: 'Cantidad total de la venta',
        initialUnitValue: 'Valor unitario inicial',
        totalInitialValue: 'Valor inicial total',
        unitMarketValue: 'Valor de mercado unitario',
        totalMarketValue: 'Valor de mercado total',
        originMarketValue: 'Valor de mercado de origen',
        notValidityEtiquettes:
          '¡La etiqueta 2 debe ser más grande que la etiqueta 1!',
        notValidityEtiquettesNullable:
          '¡La etiqueta 1 es obligatoria si se completa la etiqueta 2!',
      },
      createPhrase: {
        phrase: 'Frase',
        phrases: 'Frases',
        enterPhrase: 'Ingrese el Frase',
        selectPhrase: 'Selecione a Frase',
        enterName: 'Ingrese el nombre',
        newPhrase: 'Nuevo Frase',
        editPhrase: 'Editar Frase',
        createPhrase: '¡El frase se creó correctamente!',
        editPhrasesuccessfully: '¡El frase editó con éxito!',
        deletePhrase: 'Eliminar Frase',
      },
      createConditions: {
        conditions: 'Condiciones',
        condition: 'Condición',
        newConditions: 'Nueva Condiciones',
        editConditions: 'Editar Condición',
        deleteCondition: 'Eliminar Condición',
        createCondition: '¡El condición se creó correctamente!',
        editConditionSuccessfully: '¡El condición editó con éxito!',
      },
      uploadPhotos: {
        andMain: 'Sí',
        isNotMain: 'No',
        photo: 'Foto',
        photoRegistration: 'Registro de Fotos',
        photoTitle: 'Elige una foto para subir',
        warningDelete: '¿Deseas borrar la foto',
        successfullyDeleted: 'eliminado exitosamente!',
        warningEdit: '¿Desea cambiar la foto como foto principal?',
        successfullyEdited: 'editado exitosamente!',
        warning: '¡La foto ya está como principal!',
        warningMaxFiles: '¡Selecciona un máximo de 30 fotos!',
      },
      approved: {
        invoiceOrSerie: 'Factura/Serie',
        receiptDate: 'Fecha Rec.',
        qtyReceipt: 'Cant. Productos Aprobados',
        qtyDisapprved: 'Cant. Productos Reprobados',
        qtyPublished: 'Cant. Productos Publicados',
        approval: 'Aprobación',
        photoList: 'Lista de Fotos',
        approvalDetails: 'Detalles de Aprobación',
        notPhotoMsg: '¡Producto sin foto vinculada!',
        approve: 'Aprobar',
        disapprove: 'Desaprobar',
        adjust: 'Ajustar',
        aprovedSuccess: '¡Producto aprobado exitosamente!',
        rejectedSuccess: '¡Producto rechazado exitosamente!',
        approved: 'Approved',
        rejected: 'Rejected',
        reasonFailure: 'Razón de la Reprobación',
        reason: 'Razón',
        enterReasin: 'Escribo el motivo de la reprobación',
      },
      publications: {
        publication: 'Publicación',
        integationLabel: 'Cód. de Integración',
        statusLabel: 'Estado de Publicación',
        newPublication: 'Nueva Integración',
        qtyProducts: 'Cant. Productos',
        publicationDate: 'Fecha de Publicación',
        publishedValue: 'Valor Publicado',
        shortDescription: 'Descripción Corta',
        enterType: 'Seleccione el tipo',
        recoveryValue: 'Valor de Recuperación',
        valueReference: 'Valor Comercial',
        valueMin: 'Valor mínimo',
        initialValue: 'Valor Inicial',
        enterValue: 'Ingrese el valor',
        registerTitle: 'Registro de Publicación',
        publish: 'Publicar',
        selectProducts: 'Seleccionar productos',
        collect: 'Recolectar',
        alertSeach:
          'Por favor, introduzca fechas con una diferencia máxima de 30 días o filtre por codigo de integración',
        minSellingValue: 'Valor de venta',
        totalItems: 'Articulos totales',
        receiveCode: 'Código de recibo',
        createSuccess: 'Publicación creada con éxito',
        notPhrase: 'Por favor, selecciona al menos una frase!',
        notProduct: 'Por favor, selecciona al menos un producto!',
        integration: 'Integracíon',
        codeOrigin: 'Cód. Recibo Original',
        selectDefaultPhoto: 'Seleccionar foto principal',
        warningPhoto: 'Seleccione la foto principal de Intergração!',
        defaultPhoto: 'Foto Principal',
      },
      reportStock: {
        codeIncoive: 'Código de orden',
        codeManager: 'Code in Manager',
        saleDate: 'Fecha de venta',
        dateShipped: 'Date Shipped',
        cancellationDate: 'Fecha de cancelación',
        integrationOrder: 'Solicitud de Integración de Datos',
        errorForm:
          'Complete todos los campos del formulario o filtre por Recibo, Etiqueta o Código. Intergrado!',
        downloadMsg:
          'La descarga del informe se completó correctamente. Archivo: report_stock.csv',
      },
      commom: {
        value: 'Valor',
        storage: 'Almacenamiento',
        detail: 'Detalle',
        details: 'Detalles',
        actions: 'Comportamiento',
        user: 'Usuario',
        order: 'Orden',
        status: 'Estado',
        initialDate: 'Fecha Inicio',
        startTime: 'Hora de Inicio',
        finalDate: 'Fecha Final',
        endTime: 'Hora de Finalización',
        totalTime: 'Tiempo Total',
        shippingCompany: 'Compañía de envios',
        branch: 'Subsidiaria',
        progress: 'Progreso',
        records: 'registros',
        product: 'Producto',
        descriptionProduct: 'Descripción del producto',
        box: 'Caja',
        pallet: 'Paleta',
        grid: 'Grade',
        size: 'Tamaño',
        invoice: 'Nota fiscal',
        recordsFound: 'Fue encontrado',
        client: 'Cliente',
        edit: 'Editar',
        saveButtonTitle: 'Ahorrar',
        orderType: 'Tipo Orden',
        resultsNotFound: 'Resultados no encontrados.',
        period: 'Período',
        orderDate: 'Fecha Orden',
        yes: 'Sí',
        no: 'No',
        create: 'Crear',
        followUp: 'Hacer un Seguimiento',
        wave: 'Ola',
        color: 'Color',
        quantity: 'Cantidad',
        toClean: 'Limpiar',
        search: 'Buscar',
        finalize: 'Finalizar',
        code: 'Código',
        successFilter: 'resultados encontrados',
        description: 'Descripción',
        error: 'Error',
        branchsNotName: '¡No hay sucursales con ese nombre!',
        toAdd: 'Para agregar',
        deposit: 'Depósito',
        level: 'Nivel',
        stock: 'Existencias',
        reports: 'Informes',
        report: 'Informe',
        totalItems: 'Total de Artículos',
        creationUser: 'Usuario de Creación',
        completionUser: 'Usuario de Finalización',
        receiptData: 'Fecha de Recepción',
        productionDate: 'Production Date',
        finallyDate: 'Fecha Completada',
        closingDate: 'Fecha Concluido',
        alertSearch:
          '¡Ingrese fechas con una diferencia máxima de 30 días o filtre por recibo o factura!',
        expand: 'Expandir',
        sellerWarnning:
          'Rellena todos los campos del formulario! CNPJ: xx.xxx.xxx/xxxx-xx',
        selectReason: 'Selecciona el motivo',
        password: 'Contraseña',
        notLeader: '¡Ups! ¡El usuario no es un líder!',
        auctionValue: 'Valor de subasta',
      },
    },
    components: {
      search: {
        title: 'Buscar',
        clear: 'Limpiar',
        advancedFilters: 'Filtros Avanzados',
        closeAdvancedFilters: 'Menos Filtros',
        upload: 'Subir Archivo',
        loading: 'Cargando',
        filter: 'Filtros',
      },
      table: {
        export: 'Exportar',
        entries: 'Registros',
        to: 'a',
        noResults: 'No se han encontrado resultados',
      },
      user: {
        greetings: 'Hola',
      },
      pageMode: {
        close: 'Cerrar',
        back: 'Volver',
        printOut: 'Imprimir',
      },
      confirmationModal: {
        close: 'Cerrar',
        confirm: 'Confirmar',
        cancel: 'Cancelar',
      },
    },
  },
};
