/* eslint-disable default-case */
import XLSX, { Column } from 'exceljs';
import { saveAs } from 'file-saver';
import { IColumn } from './types';

async function exportData<T>(
  rows: T[],
  columns: IColumn<T>[],
  customRows?: any[] | Promise<any[]>
): Promise<void> {
  const workbook = new XLSX.Workbook();
  const worksheet = workbook.addWorksheet('WorkSheet');

  worksheet.columns = columns.map(column => ({
    header: column.title,
    key: column.props[0],
  })) as Partial<Column>[];

  if (customRows instanceof Promise) {
    const resolvedCustomRows = await customRows;
    worksheet.addRows(resolvedCustomRows);
  } else if (Array.isArray(customRows)) {
    worksheet.addRows(customRows);
  } else {
    worksheet.addRows(rows);
  }

  workbook.xlsx.writeBuffer().then(data => {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, 'spreadsheet.xlsx');
  });
}

export default exportData;
