/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { getIcon } from 'utils/getImage';
import { Container } from './styles';

interface LinkProps {
  codigoSistemaMenu: number;
  codigoSistema: number;
  menu: string;
  pagina: string;
  ordem: number;
}

interface ItemLinkProps {
  id: number;
  select: string;
  openItem?: (index: any) => void;
  openedState?: number;
  links?: LinkProps[];
  closed?: boolean;
  menu?: string | undefined;
  pagina?: string;
  closeSidebarFunc: () => void;
}

const ItemLink: React.FC<ItemLinkProps> = ({
  id,
  openItem = () => {},
  openedState,
  closed,
  links,
  menu,
  pagina,
  select,
  closeSidebarFunc,
}) => {
  const { pathname } = useLocation();
  const [opened, setOpened] = useState(openedState);
  const [isSelected, setIsSelected] = useState<string>('');
  const [selectedCheck, setSelectedCheck] = useState<boolean>(false);

  const handleOpen = async () => {
    if (id === opened && id !== 0) {
      return setOpened(99);
    }
    return wrapper();
  };

  const wrapper = async () => {
    setOpened(openedState);
    openItem(id);
  };

  useEffect(() => {
    setOpened(openedState);
  }, [openedState]);
  useEffect(() => {
    const searchSelectedLinks = links?.map(link => {
      if (link.pagina.split('/')[1] === pathname.split('/')[1]) {
        return true;
      }
      return '';
    });
    const linkSelected = searchSelectedLinks?.includes(true);
    if (`${pathname}/` === select || pathname === select || linkSelected) {
      setSelectedCheck(true);
    } else {
      setSelectedCheck(false);
    }
  }, [selectedCheck, pathname, select, links]);

  return (
    <Container
      containerClosed={closed}
      opened={id === opened}
      isSelected={
        (pathname.split('/')[2] === isSelected && isSelected !== '') ||
        selectedCheck
      }
    >
      {links?.length ? (
        <button type="button" onClick={handleOpen} className="title">
          {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
          <div className="container">
            <div className="left">
              {/* @ts-ignore */}
              <div className={closed ? 'icon-closed' : 'icon'}>
                <>{getIcon(`${menu}`)}</>
              </div>

              {!closed && (
                <div className="text">
                  <p>{menu}</p>
                </div>
              )}
            </div>
            {links?.length !== 0 && (
              <div className="arrow">
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.19131 6.16289C4.12557 6.27178 3.96812 6.27178 3.90238 6.16289L0.743398 0.922072C0.67597 0.809805 0.756546 0.666859 0.88803 0.666859L7.18173 0.668882C7.31152 0.668882 7.39379 0.811154 7.32602 0.924433L4.19131 6.16289ZM4.04668 7C4.3029 7 4.55946 6.87526 4.70949 6.62544L7.98209 1.17054C8.29057 0.657082 7.92073 -1.5083e-08 7.31962 -6.76344e-08L0.77374 -6.39893e-07C0.172623 -6.92444e-07 -0.196881 0.657081 0.111263 1.17054L3.38386 6.62544C3.53423 6.87526 3.79011 7 4.04668 7Z"
                    fill="white"
                  />
                </svg>
              </div>
            )}
          </div>
        </button>
      ) : (
        <button type="button" onClick={handleOpen} className="title">
          <div className="container">
            <div className="left">
              {/* @ts-ignore */}
              <div className={closed ? 'icon-closed' : 'icon'}>
                <>{getIcon(`${menu}`)}</>
              </div>

              {!closed && (
                <div className="text">
                  <a
                    href={`${pagina}`}
                    onClick={() => window.location.replace(String(pagina))}
                    key="admin"
                  >
                    <p>{menu}</p>
                  </a>
                </div>
              )}
            </div>
            {links && (
              <div className="arrow">
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.19131 6.16289C4.12557 6.27178 3.96812 6.27178 3.90238 6.16289L0.743398 0.922072C0.67597 0.809805 0.756546 0.666859 0.88803 0.666859L7.18173 0.668882C7.31152 0.668882 7.39379 0.811154 7.32602 0.924433L4.19131 6.16289ZM4.04668 7C4.3029 7 4.55946 6.87526 4.70949 6.62544L7.98209 1.17054C8.29057 0.657082 7.92073 -1.5083e-08 7.31962 -6.76344e-08L0.77374 -6.39893e-07C0.172623 -6.92444e-07 -0.196881 0.657081 0.111263 1.17054L3.38386 6.62544C3.53423 6.87526 3.79011 7 4.04668 7Z"
                    fill="white"
                  />
                </svg>
              </div>
            )}
          </div>
        </button>
      )}
      <div className="sub-itens">
        {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
        <div className="container">
          {!!links &&
            links.map(link => (
              <div
                className="item"
                key={link.ordem}
                onClick={() => setIsSelected(link.pagina.split('/')[1])}
              >
                <div
                  className={`link ${
                    !!pathname &&
                    link.pagina.split('/')[1] === pathname.split('/')[1] &&
                    'selected'
                  }`}
                >
                  <Link
                    key={link.pagina}
                    to={link.pagina}
                    onClick={closeSidebarFunc}
                  >
                    {`${link.menu}`}
                  </Link>
                </div>
              </div>
            ))}
        </div>
      </div>
    </Container>
  );
};

export default ItemLink;
