import React from 'react';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import DateRangeIcon from '@material-ui/icons/DateRange';
import RoomIcon from '@material-ui/icons/Room';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LoopIcon from '@material-ui/icons/Loop';
import CheckIcon from '@material-ui/icons/Check';
import BarChartIcon from '@material-ui/icons/BarChart';
import GamesIcon from '@material-ui/icons/Games';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease';
import DescriptionIcon from '@material-ui/icons/Description';
import Address from '../assets/svg/Icons/Address';
import IconFlag from '../assets/svg/Icons/Flag';
import IconPlan from '../assets/svg/Icons/PickingPlan';
import IconGrid from '../assets/svg/Icons/Storage';
import Conferencia from '../assets/svg/Icons/Conferencia';
import PickingDivergente from '../assets/svg/Icons/PickingDivergente';

import Besni from '../assets/svg/Logos/Besni';
import Fullfit from '../assets/svg/Logos/Fullfit';
import Renner from '../assets/svg/Logos/Renner';

import ThumbTack from '../assets/svg/LogosDashboard/ThumbTack';
import Th from '../assets/svg/LogosDashboard/Th';
import Tags from '../assets/svg/LogosDashboard/Tags';
import ShoppingCard from '../assets/svg/LogosDashboard/ShoppingCart';
import Truck from '../assets/svg/LogosDashboard/Truck';
import Exclamation from '../assets/svg/LogosDashboard/Exclamation';

import Dashboard from '../assets/svg/LogosSidebar/Dashboard';

export const getImage = (imagemvalue: string): React.ReactElement => {
  switch (imagemvalue) {
    case 'Besni':
      return <Besni viewBox="0 0 40 8" as="Besni" width="170" height="40" />;
    case 'Renner':
      return <Renner viewBox="0 0 68 67" as="Renner" width="70" height="40" />;
    case 'Fullfit':
      return (
        <Fullfit
          width="60"
          height="70"
          viewBox="0 0 60 70"
          fill="none"
          as="Fullfit"
        />
      );
    case 'thumb-tack':
      return <ThumbTack width="15" height="22" />;
    case 'th':
      return <Th />;
    case 'tags':
      return <Tags />;
    case 'shopping-cart':
      return <ShoppingCard />;
    case 'Conferência':
      return <Conferencia />;
    case 'truck':
      return <Truck />;
    case 'exclamation':
      return <Exclamation />;
    default:
      return <Besni />;
  }
};

export const getIcon = (imagemValue: string): JSX.Element => {
  switch (imagemValue) {
    case 'Endereços utilizados':
      return (
        <Address
          as="Endereços"
          width="14"
          height="21"
          viewBox="0 0 14 14"
          fill="none"
        />
      );
    case 'Cadastrar':
    case 'Cadastro':
    case 'Register':
    case 'Registrar':
      return <PlaylistAddIcon />;
    case 'Estoque':
      return (
        <IconGrid width="19" height="16" viewBox="0 0 16 19" fill="none" />
      );
    case 'Pedidos':
      return (
        <IconFlag width="20" height="19" viewBox="0 0 20 19" fill="none" />
      );
    case 'Picking Planejado':
      return (
        <IconPlan width="20" height="19" viewBox="0 0 20 19" fill="none" />
      );
    case 'Conferência':
      return <Conferencia />;
    case 'Expedição':
    case 'Expedition':
    case 'Expedición':
      return <BarChartIcon />;
    case 'Picking Divergente':
      return <PickingDivergente />;
    case 'Insuficiente':
      return <Exclamation />;
    case 'Picking Andamento':
      return (
        <Address
          as="Picking Andamento"
          width="14"
          height="21"
          viewBox="0 0 14 14"
          fill="none"
        />
      );

    case 'Dashboard':
      return <DashboardIcon />;
    case 'Recebimento':
    case 'Recibo':
    case 'Receivement':
      return <EventAvailableIcon />;
    case 'Pedido':
    case 'Orden':
    case 'Order':
      return <DateRangeIcon />;
    case 'Conferencia':
    case 'Conference':
      return <CheckIcon />;
    case 'Armazenagem':
    case 'Almacenamiento':
    case 'Storage':
      return (
        <>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.5798 5.18386L10.1798 8.42136C10.0706 8.46913 10 8.57702 10 8.69621V17.5413C10 17.7576 10.222 17.9029 10.4202 17.8161L17.8202 14.5786C17.9294 14.5309 18 14.423 18 14.3038V5.4587C18 5.24236 17.778 5.09714 17.5798 5.18386Z"
              fill="#6993FF"
            />
            <path
              d="M8.31423 8.4235L0.414225 5.17056C0.216773 5.08926 0 5.23443 0 5.44797V14.2991C0 14.4207 0.0733646 14.5302 0.185775 14.5765L8.08578 17.8294C8.28323 17.9107 8.5 17.7656 8.5 17.552V8.70091C8.5 8.57934 8.42664 8.46979 8.31423 8.4235Z"
              fill="#6993FF"
            />
            <path
              d="M8.86584 0.0670819L1.57289 3.71355C1.34539 3.8273 1.35378 4.15478 1.58681 4.25673L8.87975 7.44739C8.95641 7.48093 9.04359 7.48093 9.12025 7.44739L16.4132 4.25673C16.6462 4.15478 16.6546 3.8273 16.4271 3.71355L9.13416 0.067082C9.04971 0.0248529 8.95029 0.0248528 8.86584 0.0670819Z"
              fill="#323F68"
            />
          </svg>
        </>
      );
    case 'Picking':
    case 'Picking ':
    case 'Cosecha':
      return <RoomIcon />;

    case 'Transferência':
    case 'Transference':
    case 'Transferir':
      return <LoopIcon />;
    case 'Produtividade':
    case 'Productivity':
    case 'Productividad':
      return <TrendingUpIcon />;
    case 'Indicador':
    case 'Indicator':
      return <FormatIndentIncreaseIcon />;
    case 'Agrupamento':
    case 'Grouping':
    case 'Agrupamiento':
      return <GamesIcon />;
    case 'Relatórios':
    case 'Reports':
    case 'Informes':
      return <DescriptionIcon />;
    case 'Módulos':
    case 'Modules':
      return (
        <>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.7071 6.70711C13.0976 6.31658 13.0976 5.68342 12.7071 5.29289C12.3166 4.90237 11.6834 4.90237 11.2929 5.29289L5.29289 11.2929C4.91431 11.6715 4.90107 12.2811 5.26285 12.6757L10.7628 18.6757C11.136 19.0828 11.7686 19.1103 12.1757 18.7372C12.5828 18.364 12.6103 17.7314 12.2372 17.3243L7.38414 12.0301L12.7071 6.70711Z"
              fill="#6993FF"
            />
            <path
              opacity="0.3"
              d="M19.7071 6.70711C20.0976 6.31658 20.0976 5.68342 19.7071 5.29289C19.3166 4.90237 18.6834 4.90237 18.2929 5.29289L12.2929 11.2929C11.9143 11.6715 11.9011 12.2811 12.2628 12.6757L17.7628 18.6757C18.136 19.0828 18.7686 19.1103 19.1757 18.7372C19.5828 18.364 19.6103 17.7314 19.2372 17.3243L14.3841 12.0301L19.7071 6.70711Z"
              fill="#6993FF"
            />
          </svg>
        </>
      );

    default:
      return <Dashboard />;
  }
};
