import Cookies from 'universal-cookie/es6/Cookies';

import { LooseAutoComplete } from 'helpers/types';

const cookies = new Cookies();

type CookieName =
  | '@pdamodules::id'
  | '@pdamodules::token'
  | '@pdamodules::login'
  | '@pdamodules::active'
  | '@pdamodules::user'
  | '@pdamodules::refreshtoken'
  | '@pdamodules::codigoCliente'
  | '@pdamodules::CodigoSistema'
  | '@pdamodules::descricaoCliente';

type PDACookies = LooseAutoComplete<CookieName>;

export const getCookie = (cookieName: PDACookies) => {
  const searchCookie = cookies.get(cookieName.toString());

  return searchCookie;
};

export const setCookie = <T>(cookie: PDACookies, value: T, maxAge?: number) => {
  return cookies.set(cookie.toString(), value, { maxAge });
};

export const removeCookie = (cookieName: PDACookies): void => {
  return cookies.remove(cookieName.toString());
};

export const removeAllCookies = (): void => {
  removeCookie('@pdamodules::id');
  removeCookie('@pdamodules::user');
  removeCookie('@pdamodules::login');
  removeCookie('@pdamodules::token');
  removeCookie('@pdamodules::active');
  removeCookie('@pdamodules::refreshtoken');
  removeCookie('@pdamodules::codigoCliente');
  removeCookie('@pdamodules::CodigoSistema');
  removeCookie('@pdamodules::descricaoCliente');
};
